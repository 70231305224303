import { createContext } from "react";
import { UserStore } from "./stores/UserStore";
import { ProposalStore } from "./stores/ProposalStore";
import { ApplicationStore } from "./stores/ApplicationStore";
import { StateMachineStore } from "./stores/StateMachineStore";
import { TeamStore } from "./stores/TeamStore";
import { OfficeStore } from "./stores/OfficeStore";
import { ConfigStore } from "./stores/ConfigStore";
import { IterationStore } from "./stores/IterationStore";
import { ProposalDiffStore } from "./stores/ProposalDiffStore";
import { EnterpriseDataStewardStore } from "./stores/EnterpriseDataStewardStore";
import { TptRoleStore } from "./stores/TptRoleStore";
import { EmailConfigurationStore } from "./stores/EmailConfigurationStore";
import { EmailHistoryStore } from "./stores/EmailHistoryStore";

export const configStore = new ConfigStore();

const Context = {
    ApplicationContext: createContext(new ApplicationStore()),
    ConfigContext: createContext(configStore),
    EmailConfigurationContext: createContext(new EmailConfigurationStore()),
    EnterpriseDataStewardContext: createContext(new EnterpriseDataStewardStore()),
    IterationContext: createContext(new IterationStore()),
    OfficeContext: createContext(new OfficeStore()),
    ProposalContext: createContext(new ProposalStore()),
    ProposalDiffContext: createContext(new ProposalDiffStore()),
    EmailHistoryContext: createContext(new EmailHistoryStore()),
    StateMachineContext: createContext(new StateMachineStore()),
    TeamContext: createContext(new TeamStore()),
    TptRoleContext: createContext(new TptRoleStore()),
    UserContext: createContext(new UserStore())
};

export default Context;