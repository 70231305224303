export enum EmailTrigger {
    StatusChange = "Status Change",
    BusinessNeedPrioritizationChange = "Business Need Prioritization Change",
    JointDirectorMeetingTimeChange = "Joint Director Meeting Time Change",
    JointDirectorMeetingTimeChangeCalendarInvite = "Joint Director Meeting Time Change [Calendar Invite]",
    AssignedToIteration = "Assigned to Iteration",
    ItOtMeetingTimeChange = "IT/OT Meeting Time Change",
    ItOtMeetingTimeChangeCalendarInvite = "IT/OT Meeting Time Change [Calendar Invite]",
    ReminderNotification = "Reminder Notification",
    RingFenceExceptionMeetingTimeChange = "Ring Fence Exception Meeting Time Change",
    RingFenceExceptionMeetingTimeChangeCalendarInvite = "Ring Fence Exception Meeting Time Change [Calendar Invite]"
}