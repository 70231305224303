import React from 'react';

const LogOut: React.FC = () => {
    const logoutUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/logout`;

    return (
        <a className='nav-link' href={logoutUrl}>Sign Out</a>
    );
};

export default LogOut;