import axios from 'axios';
import { IOffice } from '../models/IOffice';

const baseRestifyUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/Office`;
const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/office`;

const baseOptions = {
    populate: 'responsibleTeam'
};
const baseRequestOptions = { params: baseOptions };

export const getAllOffices = async (): Promise<IOffice[]> => {
    const response = await axios.get(baseRestifyUrl, baseRequestOptions);
    return response.data;
};

export const getOfficeById = async (id: string): Promise<IOffice> => {
    const response = await axios.get(`${baseRestifyUrl}/${id}`, baseRequestOptions);
    return response.data;
};

export const addOffice = async (office: IOffice): Promise<IOffice> => {
    const response = await axios.post(baseRouteUrl, office, baseRequestOptions);
    return response.data;
};

export const updateOffice = async (office: IOffice): Promise<IOffice> => {
    const response = await axios.patch(`${baseRouteUrl}/${office._id}`, office);
    return response.data;
};

export const deleteOffice = async (id: string): Promise<IOffice> => {
    const response = await axios.delete(`${baseRouteUrl}/${id}`, baseRequestOptions);
    return response.data;
};