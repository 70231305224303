import axios from 'axios';
import { IApplication } from '../models/IApplication';

const baseRestifyUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/Application`;
const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/application`;

const baseOptions = {
    populate: 'team',
};
const baseRequestOptions = { params: baseOptions };

export const getAllApplications = async (): Promise<IApplication[]> => {
    const response = await axios.get(baseRestifyUrl, baseRequestOptions);
    return response.data;
};

export const getApplicationById = async (id: string): Promise<IApplication> => {
    const response = await axios.get(`${baseRestifyUrl}/${id}`, baseRequestOptions);
    return response.data;
};

export const addApplication = async (application: IApplication): Promise<IApplication> => {
    const response = await axios.post(baseRouteUrl, application, baseRequestOptions);
    return response.data;
};

export const updateApplication = async (application: IApplication): Promise<IApplication> => {
    const response = await axios.patch(`${baseRouteUrl}/${application._id}`, application);
    return response.data;
};

export const deleteApplication = async (id: string): Promise<IApplication> => {
    const response = await axios.delete(`${baseRouteUrl}/${id}`, baseRequestOptions);
    return response.data;
};
