import { observable, computed, action, runInAction, IObservableArray, set } from 'mobx';
import * as emailConfigurationApi from '../apis/EmailConfigurationApi';
import { IEmailConfiguration } from '../models/IEmailConfiguration';
import { sortString } from '../utils/SortUtils';

export class EmailConfigurationStore {
    readonly emailConfigurations: IObservableArray<IEmailConfiguration> = observable.array([]);

    @observable selectedItemId?: string = undefined;
    @observable searchFilter = '';

    @computed
    get selectedItem(): IEmailConfiguration | undefined {
        let emailConfiguration = undefined;
        if (this.selectedItemId) {
            emailConfiguration = this.emailConfigurations.find(i => i._id === this.selectedItemId);
        }
        return emailConfiguration;
    }

    @action
    public async fetchAllItems(): Promise<void> {

        const emailConfigurations = await emailConfigurationApi.getAllEmailConfigurations();
        emailConfigurations.sort(this.sortEmailConfigs);

        runInAction(() => {
            this.emailConfigurations.replace(emailConfigurations);
        });
    }

    private readonly sortEmailConfigs = (config1: IEmailConfiguration, config2: IEmailConfiguration): number => {
        const triggerSort = sortString(config1.trigger, config2.trigger);
        if (triggerSort !== 0) {
            return triggerSort;
        }

        const newStatusSort = sortString(config1.conditions.newStatusIs, config2.conditions.newStatusIs);
        if (newStatusSort !== 0) {
            return newStatusSort;
        }

        return sortString(config1.name, config2.name);
    }

    @action
    public async fetchById(id: string): Promise<void> {
        const emailConfiguration = await emailConfigurationApi.getEmailConfigurationById(id);

        runInAction(() => {
            const match = this.emailConfigurations.find(i => i._id === id);
            if (match) {
                set(match, emailConfiguration);
            }
            else {
                this.emailConfigurations.push(emailConfiguration);
            }
        });
    }

    @action
    public async addEmailConfiguration(emailConfiguration: IEmailConfiguration) {
        const newEmailConfiguration = await emailConfigurationApi.addEmailConfiguration(emailConfiguration);
        runInAction(() => {
            this.emailConfigurations.push(newEmailConfiguration);
        });
    }

    @action
    public selectItem(id: string | undefined) {
        this.selectedItemId = id;
    }

    @action
    public updateSearchFilter(newFilter: string): void {
        this.searchFilter = newFilter;
    }

    @action
    public async updateEmailConfiguration(emailConfiguration: IEmailConfiguration) {
        const updated = await emailConfigurationApi.updateEmailConfiguration(emailConfiguration);
        runInAction(() => {
            const match = this.emailConfigurations.find(i => i._id === updated._id);
            if (match) {
                set(match, updated);
            }
            else {
                throw new Error("This should never happen - figure out why");
            }
        });
    }

    @action
    public async deleteSelectedEmailConfiguration() {
        if (!this.selectedItemId) {
            return;
        }
        const deletedItem = await emailConfigurationApi.deleteEmailConfiguration(this.selectedItemId!);

        runInAction(() => {
            const currentItem = this.emailConfigurations.find(i => i._id === deletedItem._id);
            if (currentItem) {
                this.emailConfigurations.remove(currentItem);
            }
        });
    }
}