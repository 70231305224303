import { action, computed, observable, runInAction } from 'mobx';
import { IUser } from '../models/IUser';
import * as userApi from './../apis/UserApi';
import { IProfile } from '../models/IProfile';

export class UserStore {
    @observable public user?: IUser = undefined;

    @action
    public async getUser() {
        return new Promise(async (resolve, reject) => {
            if (this.user !== undefined) {
                resolve();
            };


            try {
                const user = await userApi.getUser();
                runInAction(() => {
                    this.user = user;
                    resolve();
                });
            }
            catch (err) {
                reject(err);
            }
        });

    }

    @action
    public async updateProfile(profile: IProfile) {
        const updatedProfile = await userApi.updateProfile(profile);
        runInAction(() => {
            if (this.user) {
                this.user.profile.theme = updatedProfile.theme;
            }
        });
    }

    @action async impersonate(user: IUser) {
        const newUser = await userApi.impersonate(user);
        runInAction(() => this.user = newUser);
    }

    @computed get isUserAuthenticated(): boolean {
        return this.user !== null &&
            this.user !== undefined &&
            this.user.displayName !== undefined &&
            this.user.displayName !== null;
    }
}