import React, { useContext } from 'react';
import Context from '../../Context';
import { Theme as ThemeEnum } from '../../models/enums/Theme';
import { observer } from 'mobx-react-lite';

interface IProps { }

export const ThemeStyle: React.FC<IProps> = observer((props: IProps) => {
    const userStore = useContext(Context.UserContext);
    let styleSheetPath = undefined;
    let styleSheetOverridePath = undefined;


    if (userStore.user && userStore.user.profile && userStore.user.profile.theme === ThemeEnum.Dark) {
        styleSheetPath = "/themes/dark/bootstrap.min.css";
        styleSheetOverridePath = "/themes/dark/dark-overrides.css";

        return (<>
            <link rel="stylesheet" type="text/css" href={styleSheetPath} />
            <link rel="stylesheet" type="text/css" href={styleSheetOverridePath} />
        </>);
    }

    return null;
});