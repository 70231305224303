import axios from 'axios';
import { toast } from 'react-toastify'

export const initializeInactivityTimeouts = (inactivityWarningInMinutes: number, autoLogoutInMinutes: number) => {
    const logoutUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/logout`;
    let toastId: React.ReactText | undefined;

    const inactivityWarning = () => {
        toastId = toast.info(
            "You are about to be logged out due to inactivity, please save your work if you have any unsaved changes",
            {
                autoClose: (autoLogoutInMinutes - inactivityWarningInMinutes) * 60 * 1000
            }
        );
    }

    const logOut = () => {
        window.location.href = logoutUrl;
    }

    let inactivityWarningTimeout: NodeJS.Timeout;
    let autoLogOutTimeout: NodeJS.Timeout;

    const resetInactivityTimeouts = (inactivityWarningInMinutes: number, autoLogoutInMinutes: number) => {
        if (toastId !== undefined) {
            toast.dismiss(toastId);
            toastId = undefined;
        }

        if (inactivityWarningTimeout !== undefined) {
            clearTimeout(inactivityWarningTimeout);
        }
        if (autoLogOutTimeout !== undefined) {
            clearTimeout(autoLogOutTimeout);
        }
        inactivityWarningTimeout = setTimeout(inactivityWarning, inactivityWarningInMinutes * 1000 * 60);
        autoLogOutTimeout = setTimeout(logOut, autoLogoutInMinutes * 1000 * 60);
    }

    axios.interceptors.response.use(
        (request) => {
            if (request.config.url !== `${process.env.REACT_APP_SERVER_BASE_URL}/api/user`) {
                resetInactivityTimeouts(inactivityWarningInMinutes, autoLogoutInMinutes);
            }
            return request;
        }
    );
}