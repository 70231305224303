import { TptRole } from "../models/enums/TptRole";
import { IUser } from "../models/IUser";
import { IProposal } from "../models/IProposal";
import { IStateMachineDefinition } from "../models/state-machine/IStateMachineDefinition";
import { ProposalStatus } from "../models/enums/ProposalStatus";
import { IStateDefinition } from "../models/state-machine/IStateDefinition";

export const isAuthorized = (proposal: IProposal, user: IUser, roles: TptRole[]): boolean => {
    for (const role of roles) {
        if (isAuthorizedSingle(proposal, user, role)) {
            return true;
        }
    }
    return false;
}

const isAnyOverlap = (srsIdList1: number[], srsIdList2: number[]): boolean => {
    for (const srsId of srsIdList1) {
        if (srsIdList2.some(i => i === srsId)) {
            return true;
        }
    }
    return false;
};

const isAuthorizedSingle = (proposal: IProposal, user: IUser, role: TptRole): boolean => {
    // The logged in user is representing themselves and anyone they are a delegate for
    const srsIdsRepresentedByUser = (user.delegateFor || []).map(i => i.srsId);
    srsIdsRepresentedByUser.push(user.srsId);

    switch (role) {
        case TptRole.Admin:
            return user.isAdmin;
        case TptRole.Originator:
            return (
                proposal.createdBy !== undefined &&
                proposal.createdBy !== null &&
                srsIdsRepresentedByUser.some(i => i === proposal.createdBy!.srsId)
            ) ||
                (
                    proposal.coOriginators !== undefined &&
                    proposal.coOriginators !== null &&
                    isAnyOverlap(proposal.coOriginators.map(i => i.srsId), srsIdsRepresentedByUser)
                );
        case TptRole.AnySherpa:
            return user.isAnySherpa;
        case TptRole.AssignedSherpa:
            if (!proposal.office || !proposal.office.sherpas) {
                return false;
            }
            return isAnyOverlap(proposal.office.sherpas.map(i => i.srsId), srsIdsRepresentedByUser);
        case TptRole.AssignedSponsor:
            if (proposal.sponsors === undefined) {
                return false;
            }
            const sponsorSrsIds = proposal.sponsors.map(i => i.srsId);
            return isAnyOverlap(sponsorSrsIds, srsIdsRepresentedByUser);
        case TptRole.AssignedFunctionalCoordinator:
            if (proposal.functionalApplicationCoordinator === undefined ||
                proposal.functionalApplicationCoordinator === null) {
                return false;
            }
            const functionalCoordinatorSrsId = proposal.functionalApplicationCoordinator.srsId;
            return srsIdsRepresentedByUser.some(i => i === functionalCoordinatorSrsId);

        case TptRole.AssignedBusinessAnalyst:
            if (proposal.businessAnalysts === undefined || proposal.businessAnalysts.length === 0) {
                return false;
            }
            const businessAnalystSrsIds = proposal.businessAnalysts.map(i => i.srsId);
            return isAnyOverlap(businessAnalystSrsIds, srsIdsRepresentedByUser);
        case TptRole.JointDirector:
            return user.isJointDirector;
        case TptRole.CostCenterManager:
            if (!proposal.office || !proposal.office.costCenterManager) {
                return false;
            }
            const costCenterManagerSrsId = proposal.office.costCenterManager.srsId;
            return srsIdsRepresentedByUser.some(i => i === costCenterManagerSrsId);
        case TptRole.AnyProductManager:
            return user.isAnyProductManager;
        case TptRole.AssignedProductManager:
            if (!proposal.office || !proposal.office.productManager) {
                return false;
            }
            const projectManagerSrsId = proposal.office.productManager.srsId;
            return srsIdsRepresentedByUser.some(i => i === projectManagerSrsId);
        case TptRole.AnySectionManager:
            return user.isAnySectionManager;
        case TptRole.AssignedSectionManager:
            if (!proposal.team || !proposal.team.sectionManager) {
                return false;
            }
            const sectionManagerSrsId = proposal.team.sectionManager.srsId;
            return srsIdsRepresentedByUser.some(i => i === sectionManagerSrsId);
        case TptRole.Executive:
            return user.isExecutive;
        case TptRole.ItOtTeamMember:
            return user.isItOtTaskTeamMember;
        case TptRole.OrganizationChangeManagement:
            return user.isOcm;
        case TptRole.LoggedInUser:
            return user !== undefined && user !== null;

        default: throw new Error(`Need to implement isAuthorized check for ${role}`);
    }
};

export const getStatusesWhereSpecifiedPropertyIsSpecifiedAsAvailableForUpdate = (
    stateMachineDefinition: IStateMachineDefinition,
    property: string): Array<ProposalStatus> => {
    const validStatuses: Array<ProposalStatus> = [];

    for (const currentState of stateMachineDefinition.states) {
        const match = currentState.possibleActions.find(i =>
            i.action.proposalFieldsAvailableForUpdate !== undefined &&
            i.action.proposalFieldsAvailableForUpdate.indexOf(property) !== -1);

        if (match !== undefined) {
            validStatuses.push(currentState.state);
        }
    }

    return validStatuses;
}

export const canDeleteDocument = (
    stateMachineDefinition: IStateMachineDefinition,
    currentProposal: IProposal,
    category: string,
    uploadingUserSrsId: number | undefined,
    user: IUser): boolean => {
    const currentStatus = currentProposal.status;

    const currentState = stateMachineDefinition.states.find((i: IStateDefinition) => i.state === currentStatus);
    if (currentState === undefined) {
        throw new Error(`Need to implement status change logic for the '${currentStatus}' status`);
    }

    const authorizations = currentState.possibleActions
        .filter(i => isAuthorized(currentProposal, user, i.authorized))
        .filter(i => {
            if (i.action.documentOptions === undefined) {
                return false;
            }
            if (i.action.documentOptions.allowedDeleteAllCategories !== undefined &&
                i.action.documentOptions.allowedDeleteAllCategories.indexOf(category) !== -1) {
                return true;
            }
            if (i.action.documentOptions.allowedDeleteOnlyMineCategories !== undefined &&
                i.action.documentOptions.allowedDeleteOnlyMineCategories.indexOf(category) !== -1) {
                return uploadingUserSrsId === user.srsId;
            }
            return false;
        });

    return authorizations.length > 0;
}

export const canUploadDocument = (
    stateMachineDefinition: IStateMachineDefinition,
    currentProposal: IProposal,
    category: string,
    user: IUser): boolean => {
    const currentStatus = currentProposal.status;

    const currentState = stateMachineDefinition.states.find((i: IStateDefinition) => i.state === currentStatus);
    if (currentState === undefined) {
        throw new Error(`Need to implement status change logic for the '${currentStatus}' status`);
    }

    const authorizations = currentState.possibleActions
        .filter(i => isAuthorized(currentProposal, user, i.authorized))
        .filter(i => {
            if (i.action.documentOptions === undefined) {
                return false;
            }
            if (i.action.documentOptions.allowedUploadCategories !== undefined &&
                i.action.documentOptions.allowedUploadCategories.indexOf(category) !== -1) {
                return true;
            }
            return false;
        });

    return authorizations.length > 0;
}