export function enumToArray(enumeration: any, initialEntry?: string): string[] {
    const array = Object.keys(enumeration)
        .map(k => enumeration[k as any])
        .filter(i => typeof i === 'string');
    if (initialEntry !== undefined) {
        array.unshift(initialEntry);
    }
    return array;
}

export const enumToNumberArray = (enumeration: any, initialEntry?: number | null): number[] => {
    const enumValues: Array<number> = [];

    for (const value in enumeration) {
        if (typeof enumeration[value] === 'number') {
            enumValues.push(enumeration[value]);
        }
    }

    return enumValues;
}