export enum TptRole {
    Admin,
    Originator,
    AssignedSponsor,
    AssignedFunctionalCoordinator,
    AssignedBusinessAnalyst,
    JointDirector,
    CostCenterManager,
    AssignedSherpa,
    AnySherpa,
    AssignedProductManager,
    AnyProductManager,
    AssignedSectionManager,
    AnySectionManager,
    ItOtTeamMember,
    OrganizationChangeManagement,
    Executive,
    LoggedInUser
}