import { observable, computed, action, runInAction, IObservableArray, set } from 'mobx';
import * as iterationApi from '../apis/IterationApi';
import { IIteration } from '../models/IIteration';

export class IterationStore {
    readonly iterations: IObservableArray<IIteration> = observable.array([]);

    @observable selectedItemId?: string = undefined;
    @observable searchFilter: string = '';

    @computed
    get filteredItems(): IIteration[] {
        return this.iterations.filter(
            (iteration: IIteration) =>
                (iteration.name && iteration.name.indexOf(this.searchFilter) !== -1));
    }

    @computed
    get selectedItem(): IIteration | undefined {
        let iteration = undefined;
        if (this.selectedItemId) {
            iteration = this.iterations.find(i => i._id === this.selectedItemId);
        }
        return iteration;
    }

    @action
    public async fetchAllItems(): Promise<void> {

        const iterations = await iterationApi.getAllIterations();

        runInAction(() => {
            this.iterations.replace(iterations);
        });
    };

    @action
    public async fetchById(id: string): Promise<void> {
        const iteration = await iterationApi.getIterationById(id);

        runInAction(() => {
            const match = this.iterations.find(i => i._id === id);
            if (match) {
                set(match, iteration);
            }
            else {
                this.iterations.push(iteration);
            }
        });
    }

    @action
    public selectItem(id: string | undefined) {
        this.selectedItemId = id;
    }

    @action
    public async deleteSelectedIteration() {
        if (!this.selectedItemId) {
            return;
        }
        const deletedItem = await iterationApi.deleteIteration(this.selectedItemId!);

        runInAction(() => {
            const currentItem = this.iterations.find(i => i._id === deletedItem._id);
            if (currentItem) {
                this.iterations.remove(currentItem);
            }
        });
    }
}