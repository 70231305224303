import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { hot, AppContainer } from 'react-hot-loader';

const rootEl = document.getElementById('root');
const Root = () => (
    <AppContainer>
        <App />
    </AppContainer>
);

ReactDOM.render(<Root />, rootEl);

if ((module as any).hot) {
    const render = () => {
        try {
            const NextApp = require('./components/App').default;
            ReactDOM.render(
                <NextApp loadMetadata={false} />,
                rootEl
            );
        }
        catch (error) {
            console.log(error);
        }
    }

    (module as any).hot.accept('./components/App', () => {
        setTimeout(render);
    });
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


export default hot(module)(App)