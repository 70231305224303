import { observable, action, runInAction, IObservableArray } from 'mobx';
import * as emailHistoryApi from '../apis/EmailHistoryApi';
import { IEmailHistory } from '../models/IEmailHistory';

export class EmailHistoryStore {
    readonly emailHistory: IObservableArray<IEmailHistory> = observable.array([]);

    @action
    public async fetchAllItems(proposalId: string): Promise<void> {

        const emailHistory = await emailHistoryApi.getEmailHistory(proposalId);

        runInAction(() => {
            this.emailHistory.replace(emailHistory);
        });
    };
}