import axios from 'axios';
import { IEmailConfiguration } from '../models/IEmailConfiguration';
import { EmailTrigger } from '../models/enums/EmailTrigger';

const baseRestifyUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/EmailConfiguration`;
const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/email-configuration`;

export const getAllEmailConfigurations = async (): Promise<IEmailConfiguration[]> => {
    const response = await axios.get(baseRestifyUrl);
    return response.data;
};

export const getAllReminderEmailConfigurations = async (): Promise<IEmailConfiguration[]> => {
    const queryPart = {
        trigger: { "$eq": EmailTrigger.ReminderNotification }
    };
    const options = {
        query: JSON.stringify(queryPart)
    };

    const response = await axios.get(baseRestifyUrl, { params: options });
    return response.data;
};

export const getEmailConfigurationById = async (id: string): Promise<IEmailConfiguration> => {
    const response = await axios.get(`${baseRestifyUrl}/${id}`);
    return response.data;
};

export const addEmailConfiguration = async (emailConfiguration: IEmailConfiguration): Promise<IEmailConfiguration> => {
    const response = await axios.post(baseRouteUrl, emailConfiguration);
    return response.data;
};

export const updateEmailConfiguration = async (emailConfiguration: IEmailConfiguration): Promise<IEmailConfiguration> => {
    const response = await axios.patch(`${baseRouteUrl}/${emailConfiguration._id}`, emailConfiguration);
    return response.data;
};

export const deleteEmailConfiguration = async (id: string): Promise<IEmailConfiguration> => {
    const response = await axios.delete(`${baseRouteUrl}/${id}`);
    return response.data;
};