import { observable, action, runInAction, IObservableArray } from 'mobx';
import * as proposalDiffApi from '../apis/ProposalDiffApi';
import { IProposalDiff } from '../models/IProposalDiff';

export class ProposalDiffStore {
    readonly proposalDiffs: IObservableArray<IProposalDiff> = observable.array([]);

    @action
    public async fetchAllItems(proposalId: string): Promise<void> {

        const proposalDiffs = await proposalDiffApi.getAllProposalDiffs(proposalId);

        runInAction(() => {

            // Sort in descending order
            proposalDiffs.sort((a: IProposalDiff, b: IProposalDiff): number => {
                return a.changedAt < b.changedAt
                    ? 1
                    : a.changedAt > b.changedAt
                        ? -1
                        : 0;
            });

            this.proposalDiffs.replace(proposalDiffs);
        });
    };
}