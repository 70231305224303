import { IStateMachineDefinition } from "../models/state-machine/IStateMachineDefinition";
import { IUser } from "../models/IUser";
import { IProposal } from "../models/IProposal";
import { ProposalStatus } from "../models/enums/ProposalStatus";
import { IActionDefinition } from "../models/state-machine/IActionDefinition";
import * as sharedLogic from "./SharedLogic";
import { TptRole } from "../models/enums/TptRole";

const getAuthorizedActions = (
    stateMachineDefinition: IStateMachineDefinition,
    proposal: IProposal,
    user: IUser): IActionDefinition[] => {
    const currentState = stateMachineDefinition.states.find(i => i.state === proposal.status);
    if (currentState === undefined) {
        return [];
    }

    return currentState.possibleActions
        .filter(i => sharedLogic.isAuthorized(proposal, user, i.authorized));
}

const getAuthorizedActionsByStatusAndRole = (
    stateMachineDefinition: IStateMachineDefinition,
    proposalStatus: ProposalStatus,
    role: TptRole): IActionDefinition[] => {
    const currentState = stateMachineDefinition.states.find(i => i.state === proposalStatus);
    if (currentState === undefined) {
        return [];
    }

    return currentState.possibleActions
        .filter(i => i.authorized.indexOf(role) !== -1);
}

export const isFieldUpdatable = (
    stateMachineDefinition: IStateMachineDefinition,
    proposal: IProposal,
    field: string,
    user: IUser): boolean => {
    const isUpdatable = isFieldUpdatableCore(stateMachineDefinition, proposal, field, user);
    if (!isUpdatable || field !== 'coOriginators') {
        return isUpdatable;
    }
    return canUpdateCoOriginator(proposal, user);
}

const isFieldUpdatableCore = (
    stateMachineDefinition: IStateMachineDefinition,
    proposal: IProposal,
    field: string,
    user: IUser): boolean => {

    if (proposal._id === undefined) {
        return true;
    }

    const authorizations = getAuthorizedActions(stateMachineDefinition, proposal, user);

    // If authorized to update under any possible action then user is authorized to update
    for (const authorization of authorizations) {
        const action = authorization.action;
        if (action.allFieldsNotExcludedAreUpdatable) {
            if (action.proposalFieldsUpdateExclusionList === undefined || action.proposalFieldsUpdateExclusionList.length === 0) {
                return true;
            }
            else {
                if (action.proposalFieldsUpdateExclusionList.indexOf(field) === -1) {
                    return true;
                }
            }
        }
        else {
            if (action.proposalFieldsAvailableForUpdate && action.proposalFieldsAvailableForUpdate.length > 0) {
                const fieldUpdatable = action.proposalFieldsAvailableForUpdate.indexOf(field) !== -1;
                if (fieldUpdatable) {
                    return true;
                }
            }
        }
    }

    return false;
}


const canUpdateCoOriginator = (proposal: IProposal, user: IUser): boolean => {
    // Weird rule where only real originator can add co-originator. Co-originators can do anything else an originator can do
    return !proposal || !proposal.createdBy || proposal.createdBy.srsId === user.srsId || user.isAdmin || user.isAnySherpa;
}

export const canUpdateStatusTo = (
    stateMachineDefinition: IStateMachineDefinition,
    proposal: IProposal,
    statuses: Array<ProposalStatus>,
    user: IUser): boolean => {

    const authorizations = getAuthorizedActions(stateMachineDefinition, proposal, user);

    // If authorized to update under any possible action then user is authorized to update
    for (const authorization of authorizations) {
        const action = authorization.action;
        if (action.stateChangeOptions.length === 0) {
            continue;
        }
        const notAuthorized = statuses.filter(i => action.stateChangeOptions.indexOf(i) === -1);
        // If user is authorized for all statuses passed in then return true
        if (notAuthorized.length === 0) {
            return true;
        }
    }

    return false;
};

export const getStateChangeOptions = (
    stateMachineDefinition: IStateMachineDefinition,
    proposalStatus: ProposalStatus,
    role: TptRole): ProposalStatus[] => {
    const authorizedActions = getAuthorizedActionsByStatusAndRole(stateMachineDefinition, proposalStatus, role);
    const stateChangeOptions = [];
    for (const authorizedAction of authorizedActions) {
        for (const option of authorizedAction.action.stateChangeOptions) {
            if (stateChangeOptions.indexOf(option) === -1) {
                stateChangeOptions.push(option);
            }
        }
    }
    return stateChangeOptions;
}

export const canDeleteDocument = (
    stateMachineDefinition: IStateMachineDefinition,
    currentProposal: IProposal,
    category: string,
    uploadingUserSrsId: number | undefined,
    user: IUser): boolean => {
    // If proposal has not been saved yet allow deletes
    if (currentProposal._id === undefined) {
        return true;
    }
    return sharedLogic.canDeleteDocument(stateMachineDefinition, currentProposal, category, uploadingUserSrsId, user);
}
export const canUploadDocument = (
    stateMachineDefinition: IStateMachineDefinition,
    currentProposal: IProposal,
    category: string,
    user: IUser): boolean => {

    // If proposal has not been saved yet allow uploads
    if (currentProposal._id === undefined) {
        return true;
    }
    return sharedLogic.canUploadDocument(stateMachineDefinition, currentProposal, category, user);
}

export const getBusinessNeedQueueStatuses = (stateMachineDefinition: IStateMachineDefinition): Array<ProposalStatus> => {
    return sharedLogic.getStatusesWhereSpecifiedPropertyIsSpecifiedAsAvailableForUpdate(stateMachineDefinition, 'businessNeedPriority');
}

export const getCapacityQueueStatuses = (stateMachineDefinition: IStateMachineDefinition): Array<ProposalStatus> => {
    return sharedLogic.getStatusesWhereSpecifiedPropertyIsSpecifiedAsAvailableForUpdate(stateMachineDefinition, 'capacityPriority');
}