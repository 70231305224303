import { observable, computed, action, runInAction, IObservableArray, set } from 'mobx';
import * as teamApi from '../apis/TeamApi';
import { ITeam } from '../models/ITeam';

export class TeamStore {
    readonly teams: IObservableArray<ITeam> = observable.array([]);

    @observable selectedItemId?: string = undefined;
    @observable searchFilter: string = '';

    @computed
    get filteredItems(): ITeam[] {
        return this.teams.filter(
            (team: ITeam) =>
                (team.name && team.name.indexOf(this.searchFilter) !== -1) ||
                (team.abbreviation && team.abbreviation.indexOf(this.searchFilter) !== -1));
    }

    @computed
    get selectedItem(): ITeam | undefined {
        let team = undefined;
        if (this.selectedItemId) {
            team = this.teams.find(i => i._id === this.selectedItemId);
        }
        return team;
    }

    @computed
    get selectedItemDisplay(): string {
        if (this.selectedItem) {
            return `${this.selectedItem.abbreviation} - ${this.selectedItem.name}`;
        }
        return "N/A";
    }

    @action
    public async fetchAllItems(): Promise<void> {
        const teams = await teamApi.getAllTeams();

        runInAction(() => {
            this.teams.replace(teams);
        });
    };

    @action
    public async fetchById(id: string): Promise<void> {
        const team = await teamApi.getTeamById(id);

        runInAction(() => {
            const match = this.teams.find(i => i._id === id);
            if (match) {
                set(match, team);
            }
            else {
                this.teams.push(team);
            }
        });
    }

    @action
    public selectItem(id: string | undefined) {
        this.selectedItemId = id;
    }

    @action
    public async deleteSelectedTeam() {
        if (!this.selectedItemId) {
            return;
        }
        const deletedItem = await teamApi.deleteTeam(this.selectedItemId!);

        runInAction(() => {
            const currentItem = this.teams.find(i => i._id === deletedItem._id);
            if (currentItem) {
                this.teams.remove(currentItem);
            }
        });
    }
}