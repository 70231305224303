import axios from 'axios';
import { ITeam } from '../models/ITeam';

const baseRestifyUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/Team`;
const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/team`;

export const getAllTeams = async (): Promise<ITeam[]> => {
    const response = await axios.get(baseRestifyUrl);
    return response.data;
};

export const getTeamById = async (id: string): Promise<ITeam> => {
    const response = await axios.get(`${baseRestifyUrl}/${id}`);
    return response.data;
};

export const addTeam = async (team: ITeam): Promise<ITeam> => {
    const response = await axios.post(baseRouteUrl, team);
    return response.data;
};

export const updateTeam = async (team: ITeam): Promise<ITeam> => {
    const response = await axios.patch(`${baseRouteUrl}/${team._id}`, team);
    return response.data;
};

export const deleteTeam = async (id: string): Promise<ITeam> => {
    const response = await axios.delete(`${baseRouteUrl}/${id}`);
    return response.data;
};