import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "filepond-polyfill";
import React from 'react';
import './App.css';
import { Router } from "react-router-dom";
import Routes from './Routes';
import { initializeIcons } from '../Icons';
import { initializeApi } from '../apis/Index';
import history from '../utils/History';
import { appInsights } from '../utils/AppInsightsUtils';
import { ToastContainer } from 'react-toastify';

const App: React.FC = () => {

  initializeApi();
  initializeIcons();

  history.listen((location, action) => {
    appInsights.trackEvent({
      name: 'Route Change',
      properties: {
        route: location.pathname
      }
    });
  });

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
      >
      </ToastContainer>
      <Router history={history} >
        <Routes></Routes>
      </Router>
    </>
  );

}

export default App