import axios from 'axios';
import { IUser } from '../models/IUser';
import { IProfile } from '../models/IProfile';

const baseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/user`;
const profileUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/profile`;


export const getUser = async (): Promise<IUser> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const impersonate = async (user: IUser): Promise<IUser> => {
    const response = await axios.post(`${baseUrl}/impersonate`, user);
    return response.data;
}

export const updateProfile = async (profile: IProfile): Promise<IProfile> => {
    const response = await axios.post(`${profileUrl}/${profile.srsId}`, profile);
    return response.data;
}

export const getUserPhotoUri = async (azureAdOid: string | null | undefined): Promise<string | null> => {
    if (azureAdOid === undefined || azureAdOid === null) {
        return null;
    }
    const response = await axios.get(`${profileUrl}/${azureAdOid}/photo`);
    return response.data;
}
