import axios from 'axios';
import { IStaff } from '../models/IStaff';

const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/tptRole`;

export const getAdmins = async (): Promise<IStaff[]> => {
    const response = await axios.get(`${baseRouteUrl}/admins`);
    return response.data;
};

export const getItOtTeam = async (): Promise<IStaff[]> => {
    const response = await axios.get(`${baseRouteUrl}/itOtTeam`);
    return response.data;
};

export const getOrganizationalChangeManagers = async (): Promise<IStaff[]> => {
    const response = await axios.get(`${baseRouteUrl}/ocms`);
    return response.data;
};

export const getJointDirectors = async (): Promise<IStaff[]> => {
    const response = await axios.get(`${baseRouteUrl}/jointDirectors`);
    return response.data;
};

export const getExecutives = async (): Promise<IStaff[]> => {
    const response = await axios.get(`${baseRouteUrl}/executives`);
    return response.data;
};