import { ProposalStatus } from "../models/enums/ProposalStatus";
import { enumToArray } from "./EnumUtils";
import { IProposal } from "../models/IProposal";
import { SortComparer, ValueType } from "@syncfusion/ej2-grids";

const proposalStatusArray = enumToArray(ProposalStatus);

export const sortString = (string1: string | null, string2: string | null): number => {
    if (string1 === null && string2 === null) {
        return 0;
    }
    if (string1 === null) {
        return -1;
    }
    if (string2 === null) {
        return 1;
    }
    return string1 > string2
        ? 1
        : string1 < string2
            ? -1
            : 0;
}

export const sortStrings = (
    primaryString1: string | null,
    primaryString2: string | null,
    secondaryString1: string | null,
    secondaryString2: string | null): number => {
    const primaryResult = sortString(primaryString1, primaryString2);
    return primaryResult === 0 ? sortString(secondaryString1, secondaryString2) : primaryResult;
}

export const sortByStatusSortComparer: SortComparer = (x: ValueType, y: ValueType) => {
    return sortByStatus(x as ProposalStatus, y as ProposalStatus);
}
export const sortByStatus = (proposalStatus1: ProposalStatus, proposalStatus2: ProposalStatus): number => {
    const index1 = proposalStatusArray.indexOf(proposalStatus1);
    const index2 = proposalStatusArray.indexOf(proposalStatus2);
    return index1 > index2
        ? 1
        : index1 < index2
            ? -1
            : 0;
}

export const sortProposalOnStatus = (proposal1: IProposal, proposal2: IProposal): number => {
    return sortByStatus(proposal1.status, proposal2.status);
}