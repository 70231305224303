import { action, observable, runInAction, IObservableArray } from 'mobx';
import * as tptRoleApi from './../apis/TptRoleApi';
import { IStaff } from '../models/IStaff';
import { sortString } from '../utils/SortUtils';

export class TptRoleStore {

    readonly admins: IObservableArray<IStaff> = observable.array([]);
    readonly itOtTeam: IObservableArray<IStaff> = observable.array([]);
    readonly organizationalChangeManagers: IObservableArray<IStaff> = observable.array([]);
    readonly jointDirectors: IObservableArray<IStaff> = observable.array([]);
    readonly executives: IObservableArray<IStaff> = observable.array([]);


    @action
    public async loadAdmins() {
        if (this.admins.length > 0) {
            return;
        }
        const admins = await tptRoleApi.getAdmins();
        runInAction(() => this.admins.replace(admins));
    }

    @action
    public async loadItOtTeam() {
        if (this.itOtTeam.length > 0) {
            return;
        }
        const itOtTeam = await tptRoleApi.getItOtTeam();
        runInAction(() => this.itOtTeam.replace(itOtTeam));
    }

    @action
    public async loadOrganizationalChangeManagers() {
        if (this.organizationalChangeManagers.length > 0) {
            return;
        }
        const organizationalChangeManagers = await tptRoleApi.getOrganizationalChangeManagers();
        runInAction(() => this.organizationalChangeManagers.replace(organizationalChangeManagers));
    }

    @action
    public async loadJointDirectors(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.jointDirectors.length > 0) {
                resolve();
                return;
            }
            tptRoleApi.getJointDirectors()
                .then((jointDirectors) => {
                    runInAction(() => {
                        jointDirectors.sort((a: IStaff, b: IStaff) => {
                            const lastNameSort = sortString(a.lastName, b.lastName);
                            if (lastNameSort !== 0) {
                                return lastNameSort;
                            }
                            return sortString(a.firstName, b.firstName);
                        });
                        this.jointDirectors.replace(jointDirectors);
                        resolve();
                    });
                })
                .catch(e => {
                    reject(e);
                });
        });
    }

    @action
    public async loadExecutives() {
        if (this.executives.length > 0) {
            return;
        }
        const executives = await tptRoleApi.getExecutives();
        runInAction(() => this.executives.replace(executives));
    }
}