import { IStateMachineDefinition } from "../models/state-machine/IStateMachineDefinition";
import { observable, runInAction, action } from "mobx";
import * as stateMachineApi from './../apis/StateMachineApi';

export class StateMachineStore {
    @observable stateMachineDefinition: IStateMachineDefinition = { states: [] };

    @action
    public async fetchDefinition(): Promise<void> {
        if (this.stateMachineDefinition.states.length === 0) {
            const stateMachineDefinition = await stateMachineApi.getStateMachineDefinition();

            runInAction(() => {
                this.stateMachineDefinition = stateMachineDefinition;
            });
        }
    };
} 