import React, { useContext, CSSProperties } from 'react';
import Context from '../Context';
import { Theme } from '../models/enums/Theme';
import { observer } from 'mobx-react-lite';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ThemeSelector: React.FC = observer(() => {
    const userStore = useContext(Context.UserContext);
    if (!userStore || !userStore.user || !userStore.user.srsId) {
        return null;
    }

    const currentTheme = userStore.user && userStore.user.profile && userStore.user.profile.theme === Theme.Dark
        ? Theme.Dark
        : Theme.Standard;

    const updateTheme = () => userStore.updateProfile({
        srsId: userStore.user!.srsId,
        theme: currentTheme === Theme.Dark ? Theme.Standard : Theme.Dark
    });

    const style: CSSProperties = {
        marginTop: '7px',
        marginLeft: '7px'
    }

    return (
        <label htmlFor="icon-switch" title="Toggle between Standard to Dark mode">
            <Switch
                checked={currentTheme === Theme.Dark}
                aria-checked={currentTheme === Theme.Dark}
                onChange={updateTheme}
                uncheckedIcon={
                    <FontAwesomeIcon icon="sun" style={style} />
                }
                checkedIcon={
                    <FontAwesomeIcon icon="moon" style={style} />
                }
                className="react-switch"
                id="icon-switch"
            />
        </label>
    );
});