import axios from 'axios';
import { IIteration } from '../models/IIteration';

const baseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/Iteration`;

export const getAllIterations = async (): Promise<IIteration[]> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const getIterationById = async (id: string): Promise<IIteration> => {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
};

export const addIteration = async (iteration: IIteration): Promise<IIteration> => {
    const response = await axios.post(baseUrl, iteration);
    return response.data;
};

export const updateIteration = async (iteration: IIteration): Promise<IIteration> => {
    const response = await axios.put(`${baseUrl}/${iteration._id}`, iteration);
    return response.data;
};

export const deleteIteration = async (id: string): Promise<IIteration> => {
    const response = await axios.delete(`${baseUrl}/${id}`);
    return response.data;
};