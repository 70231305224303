import { observable, computed, action, runInAction, IObservableArray, set, IObservableObject, keys, remove } from "mobx";
import * as proposalApi from "../apis/ProposalApi";
import { IProposal } from "../models/IProposal";
import { ProposalStatus } from "../models/enums/ProposalStatus";
import { IValidationCheck } from "../models/IValidationCheck";
import { AxiosResponse } from "axios";
import { setDerivedFields } from "../utils/ProposalUtils";
import { IProposalResponse } from "../models/IProposalResponse";
import { IStateMachineDefinition } from "../models/state-machine/IStateMachineDefinition";
import { BacklogStatus } from "../models/enums/BacklogStatus";

export class ProposalStore {
  readonly activeProposals: IObservableArray<IProposal> = observable.array([]);
  readonly searchResults: IObservableArray<IProposal> = observable.array([]);
  readonly actionableProposals: IObservableArray<IProposal> = observable.array([]);
  readonly specificDashboardProposals: IObservableArray<IProposal> = observable.array([]);
  readonly validationIssues: IObservableObject = observable({});
  readonly validationIssueArray: IObservableArray<string> = observable.array([]);
  @observable selectedItemId?: string = undefined;
  @observable disableEmailNotifications = false;

  @computed
  get selectedItem(): IProposal | undefined {
    let proposal = undefined;
    if (this.selectedItemId) {
      proposal = this.activeProposals.find((i) => i._id === this.selectedItemId);
    }
    return proposal;
  }

  @action
  public setDisableEmailNotifications(value: boolean): void {
    this.disableEmailNotifications = value;
  }

  @action
  public async getCbtCapacityQueue(): Promise<Array<IProposal>> {
    const proposals = await proposalApi.getCbtCapacityQueue();
    runInAction(() => {
      this.activeProposals.replace(proposals);
    });
    return proposals;
  }

  @action
  public async updateCapacityOrder(proposals: Array<IProposal>): Promise<Array<IProposal>> {
    const updatedProposals = await proposalApi.updateCapacityOrder(proposals);
    runInAction(() => {
      this.activeProposals.replace(updatedProposals);
    });
    return updatedProposals;
  }

  @action
  public async getCapacityQueue(stateMachineDefinition: IStateMachineDefinition): Promise<IProposal[]> {
    const proposals = await proposalApi.getCapacityQueue(stateMachineDefinition);
    runInAction(() => {
      this.activeProposals.replace(proposals);
    });
    return proposals;
  }

  @action
  public async fetchAllItems(): Promise<void> {
    const proposals = await proposalApi.getAllProposals();
    proposals.forEach((i) => setDerivedFields(i));

    runInAction(() => {
      this.activeProposals.replace(proposals);
    });
  }

  @action
  public async fetchAllActionableItems(): Promise<void> {
    const proposals = await proposalApi.getAllActionableProposals();
    proposals.forEach((i) => setDerivedFields(i));

    runInAction(() => {
      this.actionableProposals.replace(proposals);
    });
  }

  @action
  public async fetchDashboardProposalsByExcludeStatuses(statuses: Array<ProposalStatus>): Promise<void> {
    const proposals = await proposalApi.getProposalsByExcludeStatuses(statuses);
    proposals.forEach((i) => setDerivedFields(i));

    runInAction(() => {
      this.specificDashboardProposals.replace(proposals);
    });
  }

  @action
  public async fetchMandatedDashboardProposals(): Promise<void> {
    const proposals = await proposalApi.getMandatedProposals();
    proposals.forEach((i) => setDerivedFields(i));

    runInAction(() => {
      this.specificDashboardProposals.replace(proposals);
    });
  }

  @action
  public async fetchMyDashboardProposals(currentUserSrsId: number): Promise<void> {
    const proposals = await proposalApi.getMyProposals(currentUserSrsId);
    proposals.forEach((i) => setDerivedFields(i));

    runInAction(() => {
      this.specificDashboardProposals.replace(proposals);
    });
  }

  @action
  public async toggleAssignedIteration(proposal: IProposal, iterationId: string): Promise<IProposal> {
    return new Promise((resolve, reject) => {
      const patch = {
        _id: proposal._id,
        iterationId: proposal.iterationId ? null : iterationId,
      } as IProposal;

      if (patch.iterationId) {
        patch.backlogStatus = BacklogStatus.InProgress;
      }

      proposalApi.updateProposal(patch, false).then((response: AxiosResponse<IProposal | IValidationCheck>) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          const updatedProposal = response.data as IProposal;
          setDerivedFields(updatedProposal);
          this.updateActiveProposalInStore(updatedProposal);
          resolve(updatedProposal);
        } else if (response.status === 500) {
          reject("Could not update Proposal");
        } else {
          reject(`Need to code to handle HTTP Status Code: ${response.status}`);
        }
      });
    });
  }

  @action
  public async fetchById(id: string): Promise<void> {
    const proposal = await proposalApi.getProposalById(id);

    runInAction(() => {
      const match = this.activeProposals.find((i) => i._id === id);
      if (match) {
        set(match, proposal);
      } else {
        this.activeProposals.push(proposal);
      }
    });
  }

  @action
  public selectItem(id: string | undefined) {
    this.selectedItemId = id;
  }

  @action
  public async revertMostRecentStatusChange(id: string) {
    const response = await proposalApi.revertMostRecentStatusChange(id);
    return this.handleSingleItemResponse(response, (proposal) => this.updateActiveProposalInStore(proposal));
  }

  @action
  public async overrideProposalStatus(id: string, newStatus: ProposalStatus) {
    const response = await proposalApi.overrideProposalStatus(id, newStatus);
    return this.handleSingleItemResponse(response, (proposal) => this.updateActiveProposalInStore(proposal));
  }

  @action
  public updateActiveProposalInStore(updatedProposal: IProposal) {
    const match = this.activeProposals.find((i) => i._id === updatedProposal._id);
    if (match) {
      set(match, updatedProposal);
    } else {
      this.activeProposals.push(updatedProposal);
    }
    this.setValidationIssues({});
  }

  @action
  public async updateProposal(proposal: IProposal): Promise<boolean> {
    const response = await proposalApi.updateProposal(proposal, this.disableEmailNotifications);
    return this.handleSingleItemResponse(response, (proposal) => this.updateActiveProposalInStore(proposal));
  }

  @action
  public async updateProposalNew(proposal: IProposal, disableEmails: boolean): Promise<IProposalResponse> {
    const response = await proposalApi.updateProposalNew(proposal, disableEmails);

    runInAction(() => {
      if (response.proposal && !response.validationIssues) {
        this.updateActiveProposalInStore(response.proposal);
      } else {
        this.setValidationIssues(response.validationIssues);
      }
    });
    return response;
  }

  @action
  public setValidationIssues(validationIssues: { [key: string]: string }): void {
    const currentKeys = keys(this.validationIssues);
    const results: string[] = [];

    for (const key of currentKeys) {
      remove(this.validationIssues, key);
    }
    for (const key of Object.keys(validationIssues)) {
      set(this.validationIssues, key, validationIssues[key]);
      results.push(validationIssues[key]);
    }

    this.validationIssueArray.replace(results);
  }

  @action
  public async updateProposalInQueue(proposal: IProposal): Promise<IProposal> {
    const response = await proposalApi.updateProposal(proposal, false);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const updatedProposal = response.data as IProposal;
      setDerivedFields(updatedProposal);
      const match = this.activeProposals.find((i) => i._id === updatedProposal._id);
      if (match !== undefined) {
        set(match, updatedProposal);
      } else {
        this.actionableProposals.push(updatedProposal);
      }
      return updatedProposal;
    } else if (response.status === 500) {
      throw new Error(`Could not update Proposal`);
    } else {
      throw new Error(`Need to code to handle HTTP Status Code: ${response.status}`);
    }
  }

  @action
  public async deleteSelectedProposal() {
    if (!this.selectedItemId) {
      return;
    }
    const response = await proposalApi.deleteProposal(this.selectedItemId!);
    this.handleSingleItemResponse(response, (deletedItem) => {
      const currentItem = this.activeProposals.find((i) => i._id === deletedItem._id);
      if (currentItem) {
        this.activeProposals.remove(currentItem);
      }
    });
  }

  @action
  public async search(searchString: string): Promise<Array<IProposal>> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await proposalApi.search(searchString);
        runInAction(() => {
          this.searchResults.replace(results);
          resolve(results);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  private handleSingleItemResponse(response: AxiosResponse<IProposal | IValidationCheck>, successFunction: (proposal: IProposal) => void): boolean {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const proposal = response.data as IProposal;
      runInAction(() => {
        setDerivedFields(proposal);
        successFunction(proposal);
      });
      return true;
    } else if (response.status === 500) {
      const validationCheck = response.data as IValidationCheck;
      if (!validationCheck.isValid) {
        runInAction(() => {
          this.setValidationIssues(validationCheck.validationIssues);
        });
      }
      return false;
    } else {
      throw new Error(`Need to code to handle HTTP Status Code: ${response.status}`);
    }
  }
}
