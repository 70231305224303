import { IProposal } from "../models/IProposal";
import { toast } from "react-toastify";
import { appInsights } from "./AppInsightsUtils";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ProposalStore } from "../stores/ProposalStore";
import { ProposalStatus } from "../models/enums/ProposalStatus";
import { getUserDisplay, getUsersDisplay } from "../services/StaffService";

export const updateProposalWithToastAndLogging = (
  proposalStore: ProposalStore,
  originalProposal: IProposal,
  updateProposal: IProposal,
  successMessage: string,
  failMessage: string,
  aiProperties?: { [key: string]: any }
): Promise<boolean> => {
  if (aiProperties === undefined) {
    aiProperties =
      originalProposal !== null && originalProposal !== undefined
        ? {
            originalStatus: originalProposal.status,
            toBeStatus: updateProposal.status,
          }
        : {};
  }

  return new Promise((resolve, reject) => {
    proposalStore.updateProposal(updateProposal).then(
      (success: boolean) => {
        if (success === true) {
          toast.success(successMessage);
          appInsights.trackEvent({
            name: successMessage,
            properties: aiProperties,
          });
          resolve(true);
        } else {
          toast.error(`ERROR: ${failMessage}`);
          appInsights.trackException({
            error: {
              name: "Proposal Update Failed",
              message: "Proposal Update Failed",
            },
            id: failMessage,
            severityLevel: SeverityLevel.Error,
            properties: aiProperties,
          });
          resolve(false);
        }
      },
      (reason) => {
        toast.error(`ERROR: ${failMessage}`);
        appInsights.trackException({
          error: reason,
          id: failMessage,
          severityLevel: SeverityLevel.Error,
          properties: aiProperties,
        });
        reject();
      }
    );
  });
};

export const getProposalSubStatus = (
  proposal: IProposal | undefined
): string | null => {
  if (proposal === undefined) {
    return null;
  }
  if (proposal.status === ProposalStatus.JointDirectorsApproval) {
    if (proposal.jointDirectorsSubStatus !== undefined) {
      return proposal.jointDirectorsSubStatus;
    }
  } else if (proposal.status === ProposalStatus.ItOtTeam) {
    if (proposal.itOtTeamSubStatus !== undefined) {
      return proposal.itOtTeamSubStatus;
    }
  }
  return null;
};

export const hasBeenInStatus = (
  proposal: IProposal,
  status: ProposalStatus
): boolean => {
  if (proposal.statusHistory === undefined) {
    return false;
  }
  const match = proposal.statusHistory.find((i) => i.status === status);
  return match !== undefined && proposal.status !== status;
};

export const hasBeenInOrIsCurrentlyInStatus = (
  proposal: IProposal,
  status: ProposalStatus
): boolean => {
  if (proposal.statusHistory === undefined) {
    return false;
  }
  const match = proposal.statusHistory.find((i) => i.status === status);
  return match !== undefined;
};

export const setDerivedFields = (proposal: IProposal): void => {
  if (proposal !== undefined && proposal !== null) {
    proposal.originatorName = getUserDisplay(proposal.createdBy);
    proposal.businessAnalystName = getUsersDisplay(proposal.businessAnalysts);
    proposal.applicationName =
      proposal.application !== undefined && proposal.application !== null
        ? proposal.application.name
        : "N/A";

    // Set EDS Name
    proposal.edsName = "N/A";
    if (
      proposal.enterpriseDataStewards &&
      proposal.enterpriseDataStewards.length > 0
    ) {
      proposal.edsName = proposal.enterpriseDataStewards
        .map((i) => getUserDisplay(i))
        .join(", ");
    }
  }
};
