import React, { useEffect, useState, useContext, CSSProperties, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from './Header';
import { Home } from './Home';
import NoMatch from './NoMatch';
import Context from '../Context';
import { observer } from 'mobx-react-lite';
import Loading from 'react-loading';
import { appInsights, loadAppInsights } from '../utils/AppInsightsUtils';
import { Footer } from './Footer';
import { ThemeStyle } from './common/ThemeStyle';
import { initializeInactivityTimeouts } from '../utils/InactivityTimeouts';
import { staffService, Environment } from '@fdot/arculus-staff-service';

const SavedProposal = React.lazy(() => import('./proposal/SavedProposal'));
const DeleteProposal = React.lazy(() => import('./proposal/DeleteProposal'));
const Impersonate = React.lazy(() => import('./admin/impersonate/Impersonate'));
const Proposal = React.lazy(() => import('./proposal/Proposal'));
const DataLoadMessage = React.lazy(() => import('./common/DataLoadMessage'));
const QrcBusinessNeedQueue = React.lazy(() => import('./proposal/QrcBusinessNeedQueue'));
const TppBusinessNeedQueue = React.lazy(() => import('./proposal/TppBusinessNeedQueue'));
const CapacityQueue = React.lazy(() => import('./proposal/CapacityQueue'));
const ApplicationDirectory = React.lazy(() => import('./application/ApplicationDirectory'));
const AddEditApplication = React.lazy(() => import('./application/AddEditApplication'));
const DeleteApplication = React.lazy(() => import('./application/DeleteApplication'));
const TeamDirectory = React.lazy(() => import('./team/TeamDirectory'));
const DeleteTeam = React.lazy(() => import('./team/DeleteTeam'));
const AddEditTeam = React.lazy(() => import('./team/AddEditTeam'));
const OfficeDirectory = React.lazy(() => import('./office/OfficeDirectory'));
const AddEditOffice = React.lazy(() => import('./office/AddEditOffice'));
const DeleteOffice = React.lazy(() => import('./office/DeleteOffice'));
const IterationDirectory = React.lazy(() => import('./iteration/IterationDirectory'));
const AddEditIteration = React.lazy(() => import('./iteration/AddEditIteration'));
const DeleteIteration = React.lazy(() => import('./iteration/DeleteIteration'));
const NotAuthorized = React.lazy(() => import('./common/NotAuthorized'));
const Search = React.lazy(() => import('./search/Search'));
const EdsDirectory = React.lazy(() => import('./enterprise-data-steward/EdsDirectory'));
const AddEditEds = React.lazy(() => import('./enterprise-data-steward/AddEditEds'));
const DeleteEds = React.lazy(() => import('./enterprise-data-steward/DeleteEds'));
const JointDirectorsDashboard = React.lazy(() => import('./dashboard/JointDirectorsDashboard'));
const ExecutivesDashboard = React.lazy(() => import('./dashboard/ExecutivesDashboard'));
const TppBacklogDashboard = React.lazy(() => import('./dashboard/TppBacklogDashboard'));
const BusinessCaseDashboard = React.lazy(() => import('./dashboard/BusinessCaseDashboard'));
const WorkCompleteDashboard = React.lazy(() => import('./dashboard/WorkCompleteDashboard'));
const IntakeDashboard = React.lazy(() => import('./dashboard/IntakeDashboard'));
const ItOtTeamDashboard = React.lazy(() => import('./dashboard/ItOtTeamDashboard'));
const MandatedDashboard = React.lazy(() => import('./dashboard/MandatedDashboard'));
const MainDashboard = React.lazy(() => import('./dashboard/main/MainDashboard'));
const ActionableDashboard = React.lazy(() => import('./dashboard/ActionableDashboard'));
const AllProposalsDashboard = React.lazy(() => import('./dashboard/AllProposalsDashboard'));
const Help = React.lazy(() => import('./help/Help'));
const MyProposalsDashboard = React.lazy(() => import('./dashboard/MyProposalsDashboard'));
const EditContact = React.lazy(() => import('./contacts/EditContact'));
const ContactDirectory = React.lazy(() => import('./contacts/ContactDirectory'));
const EmailConfigurationDirectory = React.lazy(() => import('./email-configuration/EmailConfigurationDirectory'));
const AddEditEmailConfiguration = React.lazy(() => import('./email-configuration/AddEditEmailConfiguration'));
const DeleteEmailConfiguration = React.lazy(() => import('./email-configuration/DeleteEmailConfiguration'));
const QuarterlyReleaseCycleDashboard = React.lazy(() => import('./dashboard/QuarterlyReleaseCycleDashboard'));
const CbtCapacityQueue = React.lazy(() => import('./proposal/CbtCapacityQueue'));
const SearchResults = React.lazy(() => import('./search/SearchResults'));
const CbtDashboard = React.lazy(() => import('./dashboard/CbtDashboard'));
const JointDirectorScheduling = React.lazy(() => import('./scheduling/JointDirectorScheduling'));
const ItOtScheduling = React.lazy(() => import('./scheduling/ItOtScheduling'));
const RingFenceExceptionScheduling = React.lazy(() => import('./scheduling/RingFenceExceptionScheduling'));
const Roles = React.lazy(() => import('./admin/roles/Roles'));
const BackgroundTaskDashboard = React.lazy(() => import('./cron-jobs/background-tasks/BackgroundTaskDashboard'));
const ReminderNotifications = React.lazy(() => import('./cron-jobs/reminder-notifications/ReminderNotifications'));
const AddEditReminderNotification = React.lazy(() => import('./cron-jobs/reminder-notifications/AddEditReminderNotification'));
const Roi = React.lazy(() => import('./proposal/roi/Roi'));
const RoiResults = React.lazy(() => import('./proposal/roi/RoiResults'));
const WorkflowDiagramEvaluation = React.lazy(() => import('./help/WorkflowDiagramEvaluation'));
const WorkflowDiagramFree = React.lazy(() => import('./help/WorkflowDiagramFree'));
const JdVoting = React.lazy(() => import('./voting/JdVoting'));
const Delegation = React.lazy(() => import('./delegation/Delegation'));
const ScoringMain = React.lazy(() => import('./scoring/ScoringMain'));
const ScoringSummaryMain = React.lazy(() => import('./scoring/ScoringSummaryMain'));
const Permissions = React.lazy(() => import('./help/Permissions'));

const Routes: React.FC = observer(() => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [hasErrored, setHasErrored] = useState(false);
    const userStore = useContext(Context.UserContext);
    const stateMachineStore = useContext(Context.StateMachineContext);
    const configStore = useContext(Context.ConfigContext);

    const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: '1px',
        height: '1px',
        overflow: 'hidden'
    }

    useEffect(() => {
        // 24 Hours
        const inactivityTimeout = (60 * 24) - 1;
        const warningDuration = 30
        initializeInactivityTimeouts(inactivityTimeout - warningDuration, inactivityTimeout);
        userStore.getUser().then(() => {
            if (userStore.isUserAuthenticated) {
                const stateMachinePromise = stateMachineStore.fetchDefinition();
                const configPromise = configStore.fetchConfig();

                Promise.all([stateMachinePromise, configPromise])
                    .then(() => {
                        if (!configStore.config) {
                            return;
                        }
                        loadAppInsights(configStore.config.APP_INSIGHTS_KEY);
                        let authenticatedUserId = 'unknown';
                        if (userStore.user) {
                            authenticatedUserId = `${userStore.user.firstName} ${userStore.user.lastName}`;
                            if (userStore.user.emailAddress) {
                                authenticatedUserId += ` (${userStore.user.emailAddress})`;
                            }
                            authenticatedUserId += ` [${userStore.user.srsId.toString()}]`;

                            // It isn't necessary to use the user's actual sign-in name. 
                            // It only has to be an ID that is unique to that user. 
                            // It must not include spaces or any of the characters ,;=|.
                            authenticatedUserId = authenticatedUserId.replace(/[,;=| ]+/g, "_");
                        }
                        appInsights.setAuthenticatedUserContext(authenticatedUserId);
                        staffService.initialize({
                            arculusApiKey: configStore.config.ARCULUS_API_KEY || 'NO-KEY-PROVIDED',
                            environment: process.env.REACT_APP_ENVIRONMENT === 'production'
                                ? Environment.Production
                                : Environment.Test,
                            cacheOptions: {
                                cacheDurationInMinutes: 60,
                                cachingEnabled: true,
                                slidingExpirationEnabled: true
                            }
                        });
                        setIsLoaded(true);
                    }, () => {
                        setHasErrored(true);
                        setIsLoaded(true);
                    });
            }
            else {
                setIsLoaded(true);
            }
        });
    }, [configStore, stateMachineStore, userStore]);

    const getMainLandingPage = (): JSX.Element => {
        if (userStore.isUserAuthenticated) {
            return (<Route exact path='/' component={MainDashboard} />);
        }
        else {
            return (<Route exact path='/' component={Home} />);
        }
    }

    const getAuthenticatedRoutes = (): JSX.Element[] => {
        if (!userStore.isUserAuthenticated) {
            return [];
        }

        const routes: Array<JSX.Element> = [];
        routes.push(<Route path="/Dashboard/QuarterlyReleaseCycle" key="QuarterlyReleaseCycle" exact component={QuarterlyReleaseCycleDashboard} />);
        routes.push(<Route path="/Dashboard/JointDirectors" key="JointDirectors" exact component={JointDirectorsDashboard} />);
        routes.push(<Route path="/Dashboard/Executives" key="Executives" exact component={ExecutivesDashboard} />);
        routes.push(<Route path="/Dashboard/TppBacklog" key="TppBacklog" exact component={TppBacklogDashboard} />);
        routes.push(<Route path="/Dashboard/BusinessCase" key="BusinessCase" exact component={BusinessCaseDashboard} />);
        routes.push(<Route path="/Dashboard/WorkComplete" key="WorkComplete" exact component={WorkCompleteDashboard} />);
        routes.push(<Route path="/Dashboard/Intake" key="DashboardIntake" exact component={IntakeDashboard} />);
        routes.push(<Route path="/Dashboard/ItOtTeam" key="ItOtTeam" exact component={ItOtTeamDashboard} />);
        routes.push(<Route path="/Dashboard/Mandated" key="Mandated" exact component={MandatedDashboard} />);
        routes.push(<Route path="/Dashboard/Actionable" key="Actionable" exact component={ActionableDashboard} />);
        routes.push(<Route path="/Dashboard/Cbt" key="Actionable" exact component={CbtDashboard} />);
        routes.push(<Route path="/Dashboard/All" key="All" exact component={AllProposalsDashboard} />);
        routes.push(<Route path="/Dashboard/Mine" key="Mine" exact component={MyProposalsDashboard} />);
        routes.push(<Route path='/Dashboard' key="Dashboard" exact component={MainDashboard} />);
        routes.push(<Route path='/Help' key="Help" exact component={Help} />);
        routes.push(<Route path='/Proposal/Create' key="ProposalCreate" exact component={SavedProposal} />);
        routes.push(<Route path='/TppBusinessNeedPrioritization' key="TppBusinessNeedPrioritization" component={TppBusinessNeedQueue} />);
        routes.push(<Route path='/QrcBusinessNeedPrioritization' key="QrcBusinessNeedPrioritization" component={QrcBusinessNeedQueue} />);
        routes.push(<Route path='/CapacityPrioritization' key="CapacityPrioritization" component={CapacityQueue} />);
        routes.push(<Route path='/CbtCapacityPrioritization' key="CbtCapacityPrioritization" component={CbtCapacityQueue} />);
        routes.push(<Route path="/Proposals/:id" key="ProposalId" exact component={Proposal} />);
        routes.push(<Route path='/Proposals/:id/Delete' key="ProposalDelete" component={DeleteProposal} />);
        routes.push(<Route path='/Proposals/:id/Roi/Results' key="RoiResults" component={RoiResults} />);
        routes.push(<Route path='/Proposals/:id/Roi' key="Roi" component={Roi} />);
        routes.push(<Route path='/Proposals/:id/JdVoting' key="JdVoting" component={JdVoting} />);
        routes.push(<Route path='/Proposals/:id/Scoring' key="Scoring" component={ScoringMain} />);
        routes.push(<Route path='/Proposals/:id/ScoringSummary' key="ScoringSummary" component={ScoringSummaryMain} />);
        routes.push(<Route path='/Search' key="Search" component={Search} />);
        routes.push(<Route path='/SearchResults' key="SearchResults" component={SearchResults} />);
        routes.push(<Route path='/Workflow' key="WorkflowFree" component={WorkflowDiagramFree} />);
        routes.push(<Route path='/Permissions' key="Permissions" component={Permissions} />);

        if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
            // No license for Production
            routes.push(<Route path='/WorkflowEval' key="WorkflowEvaluation" component={WorkflowDiagramEvaluation} />);
        }

        return routes;
    }

    const getAdminRoutes = (): JSX.Element[] => {
        if (!userStore.user || (!userStore.user.isAdmin && !userStore.user.isAnySherpa)) {
            return [];
        }

        const routes: Array<JSX.Element> = [];
        routes.push(<Route exact path='/Admin/Applications' key="Applications" component={ApplicationDirectory} />);
        routes.push(<Route path='/Admin/Applications/Add' key="ApplicationsAdd" component={AddEditApplication} />);
        routes.push(<Route path='/Admin/Applications/:id/Edit' key="ApplicationsEdit" component={AddEditApplication} />);
        routes.push(<Route path='/Admin/Applications/:id/Delete' key="ApplicationsDelete" component={DeleteApplication} />);
        routes.push(<Route exact path='/Admin/Teams' key="Teams" component={TeamDirectory} />);
        routes.push(<Route path='/Admin/Teams/Add' key="TeamsAdd" component={AddEditTeam} />);
        routes.push(<Route path='/Admin/Teams/:id/Edit' key="TeamsEdit" component={AddEditTeam} />);
        routes.push(<Route path='/Admin/Teams/:id/Delete' key="TeamsDelete" component={DeleteTeam} />);
        routes.push(<Route exact path='/Admin/Contacts' key="Contacts" component={ContactDirectory} />);
        routes.push(<Route path='/Admin/Contacts/:id/Edit' key="ContactsEdit" component={EditContact} />);
        routes.push(<Route exact path='/Admin/Offices' key="Offices" component={OfficeDirectory} />);
        routes.push(<Route path='/Admin/Offices/Add' key="OfficesAdd" component={AddEditOffice} />);
        routes.push(<Route path='/Admin/Offices/:id/Edit' key="OfficesEdit" component={AddEditOffice} />);
        routes.push(<Route path='/Admin/Offices/:id/Delete' key="OfficesDelete" component={DeleteOffice} />);
        routes.push(<Route exact path='/Admin/Iterations' key="Iterations" component={IterationDirectory} />);
        routes.push(<Route path='/Admin/Iterations/Add' key="IterationsAdd" component={AddEditIteration} />);
        routes.push(<Route path='/Admin/Iterations/:id/Edit' key="IterationsEdit" component={AddEditIteration} />);
        routes.push(<Route path='/Admin/Iterations/:id/Delete' key="IterationsDelete" component={DeleteIteration} />);
        routes.push(<Route exact path='/Admin/EnterpriseDataStewards' key="EnterpriseDataStewards" component={EdsDirectory} />);
        routes.push(<Route path='/Admin/EnterpriseDataStewards/Add' key="EnterpriseDataStewardsAdd" component={AddEditEds} />);
        routes.push(<Route path='/Admin/EnterpriseDataStewards/:id/Edit' key="EnterpriseDataStewardsEdit" component={AddEditEds} />);
        routes.push(<Route path='/Admin/EnterpriseDataStewards/:id/Delete' key="EnterpriseDataStewardsDelete" component={DeleteEds} />);
        routes.push(<Route exact path='/Admin/EmailConfigurations' key="EmailConfigurations" component={EmailConfigurationDirectory} />);
        routes.push(<Route path='/Admin/EmailConfigurations/Add' key="EmailConfigurationsAdd" component={AddEditEmailConfiguration} />);
        routes.push(<Route path='/Admin/EmailConfigurations/:id/Edit' key="EmailConfigurationsEdit" component={AddEditEmailConfiguration} />);
        routes.push(<Route path='/Admin/EmailConfigurations/:id/Delete' key="EmailConfigurationsDelete" component={DeleteEmailConfiguration} />);
        routes.push(<Route path='/Admin/JointDirectorScheduling' key="JointDirectorScheduling" component={JointDirectorScheduling} />);
        routes.push(<Route path='/Admin/ItOtScheduling' key="ItOtScheduling" component={ItOtScheduling} />);
        routes.push(<Route path='/Admin/RingFenceExceptionScheduling' key="RingFenceExceptionScheduling" component={RingFenceExceptionScheduling} />);
        routes.push(<Route path='/Admin/Roles' key="Roles" component={Roles} />);
        routes.push(<Route path='/Admin/BackgroundTaskDashboard' key="BackgroundTaskDashboard" component={BackgroundTaskDashboard} />);
        routes.push(<Route exact path='/Admin/ReminderNotifications' key="ReminderNotifications" component={ReminderNotifications} />);
        routes.push(<Route path='/Admin/ReminderNotifications/Add' key="ReminderNotificationsAdd" component={AddEditReminderNotification} />);
        routes.push(<Route path='/Admin/ReminderNotifications/:id/Edit' key="ReminderNotificationsEdit" component={AddEditReminderNotification} />);

        return routes;
    }

    const getTestEnvironmentRoutes = (): JSX.Element | null => {
        if (!userStore.isUserAuthenticated || process.env.REACT_APP_ENVIRONMENT === 'production') {
            return null;
        }

        return (
            <Route exact path='/Admin/Impersonate' component={Impersonate} />
        );
    }

    if (!isLoaded) {
        return <Loading></Loading>
    }

    if (hasErrored) {
        return <DataLoadMessage model={'Application Data'}></DataLoadMessage>
    }

    return (
        <>
            <ThemeStyle></ThemeStyle>
            <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
                Skip to main content
            </a>
            <Header></Header>
            <div id="maincontent" style={{ paddingBottom: '100px' }}>
                <div className="container-fluid">
                    <div className='row'>
                        <div role='main' className='col'>
                            <Suspense fallback={<Loading></Loading>}>
                                <Switch>
                                    {getMainLandingPage()}

                                    <Route path="/NotAuthorized" component={NotAuthorized}></Route>

                                    {getAuthenticatedRoutes()}
                                    {getAdminRoutes()}
                                    {getTestEnvironmentRoutes()}
                                    {(userStore.user?.isJointDirector === true
                                        || userStore.user?.isAnyProductManager === true) && (
                                            <Route path='/Admin/Delegation' key="Delegation" component={Delegation} />
                                        )}

                                    <Route component={NoMatch} />
                                </Switch>
                            </Suspense>
                        </div >
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
});

export default Routes;