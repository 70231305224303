import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { IProposal } from "../models/IProposal";
import { ProposalStatus } from "../models/enums/ProposalStatus";
import { IValidationCheck } from "../models/IValidationCheck";
import { IPriorityOrder } from "../models/IPriorityOrder";
import { IStateMachineDefinition } from "../models/state-machine/IStateMachineDefinition";
import { getCapacityQueueStatuses, getBusinessNeedQueueStatuses } from "../state-machine/ClientStateMachine";
import { PriorityCategory } from "../models/enums/PriorityCategory";
import { IProposalResponse } from "../models/IProposalResponse";
import { IShareProposalDetails } from "../models/IShareProposal.Details";
import { setDerivedFields } from "../utils/ProposalUtils";
import { ServiceRequestType } from "../models/enums/ServiceRequestType";
import { IMeetingInfo } from "../models/IMeetingInfo";
import { JointDirectorsSubStatus } from "../models/enums/JointDirectorsSubStatus";
import { ItOtTeamSubStatus } from "../models/enums/ItOtTeamSubStatus";
import { SchedulingChangePayload } from "../models/SchedulingChangePayload";
import { ScheduleType } from "../models/enums/ScheduleType";

const baseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/Proposal`;

// Not using express restify mongoose for the update because it was flattening out my
// posted data which caused issues when trying to update a null complex object with
// values - TODO stop using express restify mongoose for all proposal routes.
const nonRestifyBaseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/proposal`;

const baseOptions = {
  populate: ["application", "office", "team", "iteration"],
};
const baseRequestOptions = { params: baseOptions };

export const getAllActionableProposals = async (): Promise<IProposal[]> => {
  const response = await axios.get(`${nonRestifyBaseUrl}/actionable`);
  return response.data;
};

export const getProposalsByStatus = async (status: ProposalStatus): Promise<IProposal[]> => {
  const queryPart = {
    status,
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getProposalsByStatuses = async (statuses: Array<ProposalStatus>): Promise<IProposal[]> => {
  const queryPart = getStatusInQueryPart(statuses);
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getJointDirectorMeetingProposals = async (): Promise<IProposal[]> => {
  const queryPart = {
    status: { $eq: ProposalStatus.JointDirectorsApproval },
    jointDirectorsSubStatus: { $in: [JointDirectorsSubStatus.AwaitingScheduling, JointDirectorsSubStatus.Scheduled] },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getItOtMeetingProposals = async (): Promise<IProposal[]> => {
  const queryPart = {
    status: { $eq: ProposalStatus.ItOtTeam },
    itOtTeamSubStatus: { $in: [ItOtTeamSubStatus.AwaitingScheduling, ItOtTeamSubStatus.Scheduled] },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getRingFenceMeetingProposals = async (): Promise<IProposal[]> => {
  const queryPart = {
    status: { $eq: ProposalStatus.RingFenceExceptionApproval },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getProposalsByExcludeStatuses = async (statuses: Array<ProposalStatus>): Promise<IProposal[]> => {
  const queryPart = getStatusNotInQueryPart(statuses);
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

const getStatusInQueryPart = (statuses: Array<ProposalStatus>) => {
  return {
    status: { $in: statuses },
  };
};

const getStatusNotInQueryPart = (statuses: Array<ProposalStatus>) => {
  return {
    status: { $nin: statuses },
  };
};

const getOptions = (queryPart: any) => {
  return {
    ...baseOptions,
    query: JSON.stringify(queryPart),
  };
};

export const getMandatedProposals = async (): Promise<IProposal[]> => {
  const excludeStatuses: Array<ProposalStatus> = [
    ProposalStatus.Saved,
    ProposalStatus.NotApproved,
    ProposalStatus.Withdrawn,
    ProposalStatus.WorkCompleted,
  ];

  const queryPart = {
    status: { $nin: excludeStatuses },
    priorityCategory: PriorityCategory.Mandated,
  };

  const options = getOptions(queryPart);

  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getMyProposals = async (currentUserSrsId: number): Promise<IProposal[]> => {
  const queryPart = {
    "createdBy.srsId": currentUserSrsId,
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getAllProposals = async (): Promise<IProposal[]> => {
  const excludeStatuses: Array<ProposalStatus> = [ProposalStatus.Saved];

  const queryPart = getStatusNotInQueryPart(excludeStatuses);
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  return response.data;
};

export const getQrcBusinessNeedQueueCandidates = async (stateMachineDefinition: IStateMachineDefinition): Promise<IProposal[]> => {
  const includeStatuses: Array<ProposalStatus> = getBusinessNeedQueueStatuses(stateMachineDefinition);
  const queryPart = {
    status: { $in: includeStatuses },
    isCbt: { $ne: true },
    isOperationsAndMaintenance: { $ne: true },
    serviceRequestType: { $ne: ServiceRequestType.TechnologyProposalProcess },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  // Only return if not already assigned to an iteration
  const proposals = (response.data as Array<IProposal>).filter((i) => i.iterationId === null || i.iterationId === undefined);

  proposals.forEach((i) => setDerivedFields(i));
  proposals.sort(sortByBusinessNeed);

  return proposals;
};

export const getTppBusinessNeedQueueCandidates = async (stateMachineDefinition: IStateMachineDefinition): Promise<IProposal[]> => {
  const includeStatuses: Array<ProposalStatus> = getBusinessNeedQueueStatuses(stateMachineDefinition);
  const queryPart = {
    status: { $in: includeStatuses },
    isCbt: { $ne: true },
    isOperationsAndMaintenance: { $ne: true },
    serviceRequestType: { $eq: ServiceRequestType.TechnologyProposalProcess },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  // Only return if not already assigned to an iteration
  const proposals = (response.data as Array<IProposal>).filter((i) => i.iterationId === null || i.iterationId === undefined);

  proposals.forEach((i) => setDerivedFields(i));
  proposals.sort(sortByBusinessNeed);

  return proposals;
};

export const getCapacityQueue = async (stateMachineDefinition: IStateMachineDefinition): Promise<IProposal[]> => {
  const includeStatuses: Array<ProposalStatus> = getCapacityQueueStatuses(stateMachineDefinition);
  const queryPart = {
    status: { $in: includeStatuses },
    isCbt: { $ne: true },
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  const proposals = response.data as Array<IProposal>;

  proposals.forEach((i) => setDerivedFields(i));
  proposals.sort(sortByCapacity);

  return proposals;
};

export const getCbtCapacityQueue = async (): Promise<IProposal[]> => {
  const includeStatuses: Array<ProposalStatus> = [ProposalStatus.Approved];
  const queryPart = {
    status: { $in: includeStatuses },
    isCbt: true,
  };
  const options = getOptions(queryPart);
  const response = await axios.get(baseUrl, { params: options });
  const proposals = response.data as Array<IProposal>;

  proposals.forEach((i) => setDerivedFields(i));
  proposals.sort(sortByCapacity);

  return proposals;
};

export const getProposalById = async (id: string): Promise<IProposal> => {
  const url = `${baseUrl}/${id}`;
  const response = await axios.get(url, baseRequestOptions);
  return response.data;
};

export const addProposal = async (proposal: IProposal): Promise<IProposalResponse> => {
  const response = await axios.post(baseUrl, proposal, baseRequestOptions);

  if (response.status === 200 || response.status === 201 || response.status === 204) {
    return {
      proposal: response.data as IProposal,
      validationIssues: undefined,
    };
  } else if (response.status === 500) {
    return {
      proposal: undefined,
      validationIssues: response.data,
    };
  }
  throw new Error(`Could not handle response for add proposal with status code: ${response.status}`);
};

export const updateProposalNew = async (proposal: IProposal, disableEmails: boolean): Promise<IProposalResponse> => {
  const config: AxiosRequestConfig = {
    headers: { "disable-emails": disableEmails },
  };

  return new Promise<IProposalResponse>((resolve, reject) => {
    axios
      .patch(`${nonRestifyBaseUrl}/${proposal._id}`, proposal, config)
      .then((response: AxiosResponse<IProposal>) => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          const updatedProposal: IProposal = response.data;
          setDerivedFields(updatedProposal);
          resolve({
            proposal: updatedProposal,
            validationIssues: undefined,
          });
        } else if (response.status === 500) {
          resolve({
            proposal: undefined,
            validationIssues: response.data,
          });
        }

        reject(`Could not handle response for update proposal with status code: ${response.status}`);
      })
      .catch((error) => {
        const response = error.response;
        if (response === undefined) {
          reject(error);
        } else if (response.status === 500 && response.data.isValid === false) {
          resolve({
            proposal: undefined,
            validationIssues: response.data.validationIssues,
          });
        } else {
          reject(response);
        }
      });
  });
};

export const updateProposal = async (proposal: IProposal, disableEmails: boolean): Promise<AxiosResponse<IProposal | IValidationCheck>> => {
  try {
    const config: AxiosRequestConfig = {
      headers: { "disable-emails": disableEmails },
    };

    return await axios.patch(`${nonRestifyBaseUrl}/${proposal._id}`, proposal, config);
  } catch (error) {
    return error.response;
  }
};

export const updateBusinessNeedOrder = async (proposals: Array<IProposal>): Promise<Array<IProposal>> => {
  const errorMessage = "Issue Updating Business Need Priority";
  return new Promise((resolve, reject) => {
    const updateInfo: Array<IPriorityOrder> = proposals.map((i: IProposal, index: number) => {
      if (!i._id) {
        throw new Error("Update Info had an empty proposal");
      }
      return {
        id: i._id!,
        priority: index,
      };
    });

    axios
      .patch(`${nonRestifyBaseUrl}/update-business-need-priority`, updateInfo)
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          response.data.sort(sortByBusinessNeed);
          response.data.forEach((i: IProposal) => setDerivedFields(i));
          resolve(response.data);
        } else {
          reject(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        reject(errorMessage);
      });
  });
};

export const updateCapacityOrder = async (proposals: Array<IProposal>): Promise<Array<IProposal>> => {
  const errorMessage = "Issue Updating Business Need Priority";
  return new Promise((resolve, reject) => {
    const updateInfo: Array<IPriorityOrder> = proposals.map((i: IProposal, index: number) => {
      if (!i._id) {
        throw new Error("Update Info had an empty proposal");
      }
      return {
        id: i._id,
        priority: index,
      };
    });

    axios
      .patch(`${nonRestifyBaseUrl}/update-capacity-priority`, updateInfo)
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          const updatedProposals: Array<IProposal> = response.data;
          updatedProposals.sort(sortByCapacity);
          updatedProposals.forEach((i) => setDerivedFields(i));
          resolve(updatedProposals);
        } else {
          reject(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        reject(errorMessage);
      });
  });
};

export const deleteProposal = async (id: string): Promise<AxiosResponse<IProposal | IValidationCheck>> => {
  try {
    return await axios.delete(`${baseUrl}/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const share = async (shareDetails: IShareProposalDetails): Promise<boolean> => {
  const response = await axios.post(`${nonRestifyBaseUrl}/share`, shareDetails);

  if (response.status === 200 || response.status === 201 || response.status === 204) {
    return true;
  }
  return false;
};

export const search = async (searchString: string): Promise<Array<IProposal>> => {
  const errorMessage = "Search Failed";
  return new Promise((resolve, reject) => {
    axios
      .post(`${nonRestifyBaseUrl}/search`, { searchString })
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          const matchingProposals: Array<IProposal> = response.data;
          matchingProposals.sort(sortByCapacity);
          matchingProposals.forEach((i) => setDerivedFields(i));
          resolve(matchingProposals);
        } else {
          reject(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        reject(errorMessage);
      });
  });
};

export const revertMostRecentStatusChange = (proposalId: string): Promise<AxiosResponse<IProposal>> => {
  return axios.post(`${nonRestifyBaseUrl}/${proposalId}/status/revert`);
};

export const overrideProposalStatus = (proposalId: string, newStatus: ProposalStatus): Promise<AxiosResponse<IProposal>> => {
  return axios.post(`${nonRestifyBaseUrl}/${proposalId}/status/override/${newStatus}`);
};

export const scheduleMeeting = (payload: SchedulingChangePayload): Promise<Array<IProposal>> => {
  const errorMessage = "Meeting Scheduling Failed";
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${nonRestifyBaseUrl}/schedule-meeting`, payload)
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          const proposals: Array<IProposal> = response.data;
          proposals.forEach((i) => setDerivedFields(i));
          resolve(proposals);
        } else {
          console.log(`Response status of: ${response.status} returned while attempting to update Meeting`);
          const content = (response.data as any)?.validationIssue;
          if (content !== undefined && content !== null) {
            reject(content);
          } else {
            reject(errorMessage);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        const content = (e.response.data as any)?.validationIssue;
        if (content !== undefined && content !== null) {
          reject(content);
        } else {
          reject(errorMessage);
        }
      });
  });
};

export const deleteMeeting = async (proposalId: string, scheduleType: ScheduleType) => {
  let url: string;
  switch (scheduleType) {
    case ScheduleType.JointDirectorMeeting:
      url = `${nonRestifyBaseUrl}/${proposalId}/joint-director-meeting`;
      break;
    case ScheduleType.ItOtMeeting:
      url = `${nonRestifyBaseUrl}/${proposalId}/it-ot-meeting`;
      break;
    case ScheduleType.RingFenceExceptionMeeting:
      url = `${nonRestifyBaseUrl}/${proposalId}/ring-fence-exception-meeting`;
      break;
    default:
      throw new Error("Schedule Type not understood");
  }

  try {
    return await axios.delete(url);
  } catch (error) {
    return error.response;
  }
};

export const scheduleItOtMeeting = (payload: Array<IMeetingInfo>): Promise<Array<IProposal>> => {
  const errorMessage = "It/OT Meeting Scheduling Failed";
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${nonRestifyBaseUrl}/it-ot-meeting`, payload)
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          const proposals: Array<IProposal> = response.data;
          proposals.forEach((i) => setDerivedFields(i));
          resolve(proposals);
        } else {
          console.log(`Response status of: ${response.status} returned while attempting to update IT/OT Meeting`);
          reject(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        reject(errorMessage);
      });
  });
};

export const scheduleRingFenceExceptionMeeting = (payload: Array<IMeetingInfo>): Promise<Array<IProposal>> => {
  const errorMessage = "Ring Fence Exception Meeting Scheduling Failed";
  return new Promise((resolve, reject) => {
    return axios
      .patch(`${nonRestifyBaseUrl}/ring-fence-exception-meeting`, payload)
      .then((response: AxiosResponse<Array<IProposal>>) => {
        if (response.status === 200) {
          const proposals: Array<IProposal> = response.data;
          proposals.forEach((i) => setDerivedFields(i));
          resolve(proposals);
        } else {
          console.log(`Response status of: ${response.status} returned while attempting to update Ring Fence Exception Meeting`);
          reject(errorMessage);
        }
      })
      .catch((e) => {
        console.log(e);
        reject(errorMessage);
      });
  });
};

const sortByBusinessNeed = (a: IProposal, b: IProposal): number => {
  const aValue = a.businessNeedPriority === undefined ? 999 : a.businessNeedPriority;
  const bValue = b.businessNeedPriority === undefined ? 999 : b.businessNeedPriority;

  return aValue - bValue;
};

const sortByCapacity = (a: IProposal, b: IProposal): number => {
  const aValue = a.capacityPriority === undefined ? 999 : a.capacityPriority;
  const bValue = b.capacityPriority === undefined ? 999 : b.capacityPriority;

  return aValue - bValue;
};
