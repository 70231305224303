
import React, { useContext, useEffect } from 'react'
import localforage from 'localforage';
import Context from '../Context';

const NoMatch: React.FC = () => {

    const userStore = useContext(Context.UserContext);

    useEffect(() => {
        if (!userStore.isUserAuthenticated) {
            deepLinkUser();
        }
    });

    const deepLinkUser = () => {
        const url = window.location.href;
        localforage.setItem('deep-link-redirect-url', url).then(() => {
            window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/login`;
        });
    }

    return (
        <h1>
            Could not find Page Associated with URL!
        </h1>
    );

}

export default NoMatch