import axios from 'axios';
import history from '../utils/History';

export const initializeApi = (): void => {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.get['Pragma'] = 'no-cache';
    axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

    axios.interceptors.response.use(
        (response) => {
            // Do something with response data
            return response;
        }, (error) => {
            // Do something with response error
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                console.log(error.response);
                history.push('/NotAuthorized')
            }

            // Throw error again (may be need for some other catch)
            return Promise.reject(error);
        });
};