import React from 'react';
import { Callout, mergeStyleSets, DirectionalHint, Stack, Text, IStackStyles, IStackItemStyles, FontWeights, Persona, PersonaSize } from 'office-ui-fabric-react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { IStaff } from '../../../models/IStaff'
import LogOut from '../LogOut';
import { ThemeSelector } from '../../ThemeSelector';

interface IProps {
    showOptions: boolean;
    staff: IStaff;
    personaId: string;
    imageUrl: string | undefined;
    nameOfStaff: string;
    imageInitials: string;
    close: () => void;
}

const PhotoCallout: React.FC<IProps> = (props: IProps) => {

    const styles = mergeStyleSets({
        callout: {
            display: 'flex'
        }
    });
    const headerCalloutStyles: IStackStyles = {
        root: {
            padding: '18px 24px 12px',
            display: "flex"
        }
    }
    const titleItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            fontWeight: FontWeights.semilight
        }
    };
    const signOutItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
            marginLeft: "auto !important"
        }
    };
    const innerCalloutStyles: IStackStyles = {
        root: {
            padding: '0 24px 20px',
        }
    }
    const userImageItemStyles: IStackItemStyles = {
        root: {
            display: "flex",
        }
    };
    const userInfoStackStyles: IStackStyles = {
        root: {
            paddingTop: "2px",
        }
    };
    const stackItemStyles: IStackItemStyles = {
        root: {
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            fontSize: FontSizes.size18
        }
    };
    const boldSubTextStyles: IStackItemStyles = {
        root: {
            fontSize: FontSizes.size18,
            fontWeight: FontWeights.bold
        }
    };
    const footerCalloutStyles: IStackStyles = {
        root: {
            display: "flex"
        }
    }

    const target = document.getElementById(props.personaId);

    return (
        <Callout
            className={styles.callout}
            gapSpace={5}
            target={target}
            isBeakVisible={false}
            onDismiss={() => props.close()}
            directionalHint={DirectionalHint.bottomAutoEdge}
            setInitialFocus={true}
        >
            <Stack horizontal={true} styles={headerCalloutStyles}>
                <Stack.Item styles={titleItemStyles}>
                    <Text style={{ paddingRight: '20px' }}>Florida Department of Transportation</Text>
                </Stack.Item>
                <Stack.Item styles={signOutItemStyles}>
                    {props.showOptions && (<LogOut></LogOut>)}
                </Stack.Item>
            </Stack>
            <Stack horizontal={true} styles={innerCalloutStyles}>
                <Stack.Item styles={userImageItemStyles}>
                    <Persona
                        imageUrl={props.imageUrl}
                        imageAlt={`Profile Pic for: ${props.nameOfStaff}`}
                        size={PersonaSize.size120}
                        title={props.nameOfStaff}
                        imageInitials={props.imageInitials}
                        imageShouldFadeIn={true}
                    />
                </Stack.Item>
                <Stack horizontal={false} styles={userInfoStackStyles}>
                    <Stack.Item styles={stackItemStyles}>
                        <Text styles={boldSubTextStyles}>
                            {props.nameOfStaff}
                        </Text>
                    </Stack.Item>
                    {props.staff.positionWorkTitle &&
                        <Stack.Item styles={stackItemStyles}>
                            <Text>
                                {props.staff.positionWorkTitle}
                            </Text>
                        </Stack.Item>
                    }
                    <Stack.Item styles={stackItemStyles}>
                        <Text>
                            {props.staff.emailAddress}
                        </Text>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <Text>
                            {props.staff.phoneNumber}
                        </Text>
                    </Stack.Item>
                </Stack>
            </Stack>
            {props.showOptions && (
                <Stack horizontal={true} styles={footerCalloutStyles}>
                    <Stack.Item styles={signOutItemStyles}>
                        <Text className="my-1" style={{ color: 'black' }}>
                            Theme:&nbsp;&nbsp;
                        </Text>
                        <Text style={{ paddingBottom: '20px', paddingRight: '20px' }}>
                            <ThemeSelector></ThemeSelector>
                        </Text>

                    </Stack.Item>
                </Stack>
            )
            }
        </Callout>
    );
};

export default PhotoCallout;