import { observable, computed, action, runInAction, IObservableArray, set } from 'mobx';
import * as applicationApi from './../apis/ApplicationApi';
import { IApplication } from '../models/IApplication';

export class ApplicationStore {
    readonly applications: IObservableArray<IApplication> = observable.array([]);

    @observable selectedItemId?: string = undefined;

    private setDerivedFields = (application: IApplication): void => {
        if (application !== undefined && application !== null) {
            application.isRingFencedYesNo = application.isRingFenced ? "Yes" : "No";
        }
    }

    @computed
    get selectedItem(): IApplication | undefined {
        let application = undefined;
        if (this.selectedItemId) {
            application = this.applications.find(i => i._id === this.selectedItemId);
        }
        return application;
    }

    @computed
    get selectedItemDisplay(): string {
        if (this.selectedItem) {
            return `${this.selectedItem.abbreviation} - ${this.selectedItem.name}`;
        }
        return "N/A";
    }

    @action
    public async fetchAllItems(): Promise<void> {

        const applications = await applicationApi.getAllApplications();
        applications.forEach(i => this.setDerivedFields(i));

        runInAction(() => {
            this.applications.replace(applications);
        });
    };

    @action
    public async fetchById(id: string): Promise<void> {
        const application = await applicationApi.getApplicationById(id);
        this.setDerivedFields(application);

        runInAction(() => {
            const match = this.applications.find(i => i._id === id);
            if (match) {
                set(match, application);
            }
            else {
                this.applications.push(application);
            }
        });
    }

    @action
    public selectItem(id: string | undefined) {
        this.selectedItemId = id;
    }

    @action
    public async deleteSelectedApplication() {
        if (!this.selectedItemId) {
            return;
        }
        const deletedItem = await applicationApi.deleteApplication(this.selectedItemId!);

        runInAction(() => {
            const currentItem = this.applications.find(i => i._id === deletedItem._id);
            if (currentItem) {
                this.applications.remove(currentItem);
            }
        });
    }
}