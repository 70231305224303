import React from 'react';

export const Home: React.FC = () => {
    return (
        <div>
            <div className="container">
                <br /><br /><br />
                <h1 className="text-center">Welcome to the Technology Proposal Tracker</h1>

                <p className="text-center">
                    A technology proposal is defined as software development/purchase or collection or purchase of data.
                    This application will guide you through the process to request a project for your software.
                    You can track your proposal from Intake to project creation.
            </p>

                <div className="text-center">
                    <button
                        className="btn btn-primary"
                        type="button"
                        aria-label="Login"
                        onClick={() => window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/login`}>
                        LOGIN
                </button>
                </div>
                <br />
                <p className="text-center">
                    Please note: Access to this application is limited to FDOT personnel
            </p>

            </div>
        </div >
    );
}