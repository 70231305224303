import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export var appInsights: ApplicationInsights;

export const loadAppInsights = (APP_INSIGHTS_KEY: string) => {
    if (appInsights) {
        return;
    }

    // Create React App Note
    // There is also a built-in environment variable called NODE_ENV. 
    // You can read it from process.env.NODE_ENV. When you run npm start, 
    // it is always equal to 'development', when you run npm test it is 
    // always equal to 'test', and when you run npm run build to make a 
    // production bundle, it is always equal to 'production'. You cannot 
    // override NODE_ENV manually. This prevents developers from accidentally 
    // deploying a slow development build to production. 
    if (process.env.NODE_ENV === 'production') {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: APP_INSIGHTS_KEY,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true
            }
        });
        appInsights.loadAppInsights();
    }
    else {
        appInsights = {
            trackEvent: () => { },
            trackException: () => { },
            setAuthenticatedUserContext: () => { }
        } as any as ApplicationInsights;
    }
}