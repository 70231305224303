import { observable, computed, action, runInAction, IObservableArray, set } from 'mobx';
import * as officeApi from '../apis/OfficeApi';
import { IOffice } from '../models/IOffice';

export class OfficeStore {
    readonly offices: IObservableArray<IOffice> = observable.array([]);

    @observable selectedItemId?: string = undefined;
    @observable searchFilter: string = '';

    @computed
    get filteredItems(): IOffice[] {
        return this.offices.filter(
            (office: IOffice) =>
                (office.division && office.division.indexOf(this.searchFilter) !== -1) ||
                (office.department && office.department.indexOf(this.searchFilter) !== -1) ||
                (office.office && office.office.indexOf(this.searchFilter) !== -1));
    }

    @computed
    get selectedItem(): IOffice | undefined {
        let office = undefined;
        if (this.selectedItemId) {
            office = this.offices.find(i => i._id === this.selectedItemId);
        }
        return office;
    }

    @computed
    get selectedItemDisplay(): string {
        if (this.selectedItem) {
            return `${this.selectedItem.division} - ${this.selectedItem.department} - ${this.selectedItem.office}`;
        }
        return "N/A";
    }

    @action
    public async fetchAllItems(): Promise<void> {

        const offices = await officeApi.getAllOffices();

        runInAction(() => {
            this.offices.replace(offices);
        });
    };

    @action
    public async fetchById(id: string): Promise<void> {
        const office = await officeApi.getOfficeById(id);

        runInAction(() => {
            const match = this.offices.find(i => i._id === id);
            if (match) {
                set(match, office);
            }
            else {
                this.offices.push(office);
            }
        });
    }

    @action
    public selectItem(id: string | undefined) {
        this.selectedItemId = id;
    }

    @action
    public async deleteSelectedOffice() {
        if (!this.selectedItemId) {
            return;
        }
        const deletedItem = await officeApi.deleteOffice(this.selectedItemId!);

        runInAction(() => {
            const currentItem = this.offices.find(i => i._id === deletedItem._id);
            if (currentItem) {
                this.offices.remove(currentItem);
            }
        });
    }
}