import React, { useContext, useState, useEffect, CSSProperties } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Context from '../Context';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { getUserDisplay, getUserInitials } from '../services/StaffService';
import PhotoCallout from './common/photo/PhotoCallout';

export const Header = observer(() => {

  const dropDownMenuItemStyle: CSSProperties = {
    color: 'black'
  };

  const headerTextStyle: CSSProperties = {
    color: 'white',
    padding: '10px',
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20
  }

  const userStore = useContext(Context.UserContext);
  const proposalStore = useContext(Context.ProposalContext);

  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showCallout, setShowCallout] = useState<boolean>(false);

  useEffect(() => {
    userStore.getUser().then(
      () => setIsLoaded(true),
      () => setHasErrored(true));
  }, [userStore]);

  const getLoginLink = () => {
    if (isLoaded && !hasErrored && userStore.isUserAuthenticated) {
      return null;
    }
    const loginUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/login`;

    return (
      <a className='nav-link' href={loginUrl} style={headerTextStyle}>Log In</a>
    );
  };

  const getLoggedInUserDisplay = () => {
    const nameOfStaff = getUserDisplay(userStore.user, false, false, false, false, true);
    const initials = getUserInitials(userStore.user);

    return isLoaded && !hasErrored && userStore.user && userStore.isUserAuthenticated
      ? (
        <>
          <div className="d-none d-xl-block" style={headerTextStyle}>
            <Persona
              id="LoggedInUserPhoto"
              imageUrl={userStore.user.photoUri || undefined}
              imageAlt={`Profile Pic for: ${nameOfStaff}`}
              size={PersonaSize.size40}
              text={nameOfStaff}
              styles={{ primaryText: { color: 'white' } }}
              imageInitials={initials}
              imageShouldFadeIn={true}
              onClick={() => setShowCallout(true)}>
            </Persona>
            {showCallout && (
              <PhotoCallout
                personaId="LoggedInUserPhoto"
                showOptions={true}
                staff={userStore.user}
                imageUrl={userStore.user.photoUri || undefined}
                nameOfStaff={nameOfStaff}
                imageInitials={initials}
                close={() => setShowCallout(false)}
              >
              </PhotoCallout>
            )
            }
          </div>
        </>
      )
      : '';
  }

  const getProposalsOptions = () => {
    if (userStore.isUserAuthenticated) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Proposals
        </DropdownToggle>
          <DropdownMenu>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard'}>
              Dashboard
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Proposal/Create'} >
              Create New Proposal
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/Mine'} >
              My Proposals
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/Actionable'} >
              Actionable Proposals
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/All'} >
              All Proposals
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/Intake'} >
              Proposals in Intake
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/JointDirectors'} >
              Proposals in Joint Directors
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/BusinessCase'} >
              Proposals in Business Case
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/ItOtTeam'} >
              Proposals in IT/OT
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/Executives'} >
              Proposals in Executive
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Dashboard/TppBacklog'} >
              Proposals in TPP Backlog
            </DropdownItem>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Search'}>
              Search
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>)
    }
    return null;
  };

  const getPrioritizationQueueOptions = () => {
    if (!userStore.isUserAuthenticated || !userStore.user) {
      return null;
    }

    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Prioritization
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/TppBusinessNeedPrioritization'}>
            TPP Business Need Prioritization
          </DropdownItem>
          <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/QrcBusinessNeedPrioritization'}>
            QRC Business Need Prioritization
          </DropdownItem>
          <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/CapacityPrioritization'}>
            Capacity Prioritization
              </DropdownItem>
          <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/CbtCapacityPrioritization'}>
            CBT Prioritization
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown >
    )
  };

  const getAdministrationOptions = () => {
    if (!userStore.isUserAuthenticated || !userStore.user) {
      return null;
    }

    const { user } = userStore;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // In non production environment non-admin non-sherpa users have the ability to impersonate
      if (!user.isAdmin && !user.isAnySherpa && !user.isAnyProductManager && !user.isJointDirector) {
        return null;
      }
    }

    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Admin
        </DropdownToggle>
        <DropdownMenu>
          {getTestEnvironmentOptions()}
          {getDelegationOptions()}
          {getAdminAndSherpaRoleOnlyOptions()}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  const getTestEnvironmentOptions = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return null;
    }
    return (
      <>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Impersonate'}>
          Impersonation
        </DropdownItem>
      </>
    );
  }

  const getDelegationOptions = () => {
    if (!userStore.user || (!userStore.user.isAnyProductManager && !userStore.user.isJointDirector)) {
      return null;
    }
    return (
      <>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Delegation'}>
          Delegation
        </DropdownItem>
      </>
    );
  }

  const getAdminAndSherpaRoleOnlyOptions = () => {
    if (!userStore.user || (!userStore.user.isAdmin && !userStore.user.isAnySherpa)) {
      return null;
    }
    return (
      <>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/BackgroundTaskDashboard'}>
          Background Task Dashboard
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Applications'} >
          Manage Applications
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Contacts'} >
          Manage Contacts
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/EmailConfigurations'} >
          Manage Email Notifications
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/EnterpriseDataStewards'} >
          Manage Enterprise Data Stewards
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Iterations'}>
          Manage Iterations
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Offices'}>
          Manage Offices
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Teams'}>
          Manage Teams
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/ItOtScheduling'}>
          IT/OT Scheduling
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/JointDirectorScheduling'}>
          Joint Director Scheduling
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Permissions'}>
          Permissions
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/ReminderNotifications'}>
          Reminder Notifications
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/RingFenceExceptionScheduling'}>
          Ring Fence Exception Scheduling
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Roles'}>
          Roles
        </DropdownItem>
        <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Workflow'}>
          Workflow
        </DropdownItem>
      </>
    );
  }

  const getHelpOptions = () => {
    if (!userStore.user || !userStore.isUserAuthenticated) {
      return null;
    }
    return (
      <NavItem>
        <NavLink to="/Help" className="nav-link">Help</NavLink>
      </NavItem>
    );
  }

  const search = () => {
    proposalStore.search(searchString)
      .then((test) => {
        history.push('/SearchResults')
      })
      .catch((e) => {
        toast.error(e);
      })
  }

  const getSearchInput = () => {
    if (!userStore.isUserAuthenticated || !userStore.user) {
      return null;
    }

    return (
      <div className="d-none d-xl-block">
        <form className="form-inline my-2 my-lg-0" >
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchString}
            onKeyPress={
              (event) => {
                if (event.key === "Enter") {
                  search();
                  event.preventDefault();
                }
              }
            }
            onChange={(event) => setSearchString(event.target.value)} />
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="button"
            aria-label="Search"
            onClick={search}>
            Search
          </button>
        </form>
      </div>
    );
  }

  const getNavbar = () => {
    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand href="/" style={brandStyle}>
            <span title="Technology Proposal Tracker">
              TPT
            </span>
          </NavbarBrand>
          <NavbarToggler onClick={setIsExpanded} />
          <Collapse isOpen={isExpanded} navbar>
            <Nav navbar>
              {getAdministrationOptions()}
              {getProposalsOptions()}
              {getPrioritizationQueueOptions()}
              {getHelpOptions()}
            </Nav>
            <Nav className="ml-auto">
              {getSearchInput()}
            </Nav>
            <Nav className="ml-auto">
              <NavItem>
                {getLoggedInUserDisplay()}
              </NavItem>
              <NavItem>
                {getLoginLink()}
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }

  return getNavbar();
});