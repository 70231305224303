export enum ProposalStatus {
    Saved = "Saved",
    Submitted = "Submitted",
    SponsorApproval = "Sponsor Approval",
    FunctionalCoordinatorApproval = "Functional Coordinator Approval",
    RingFenceExceptionApproval = "Ring Fence Exception Approval",
    Validation = "Validation",
    TShirt = "T-Shirt",
    OperationsAndMaintenanceApproval = "Operations and Maintenance (O&M) Approval",
    OcmApproval = "Organizational Change Management Approval",
    QuarterlyReleaseCycleApproval = "Quarterly Release Cycle (QRC) Approval",
    TeamEstimation = "Team Estimation",
    JointDirectorsApproval = "Joint Directors Approval",
    BusinessCase = "Business Case",
    ItOtTeam = "IT/OT Team",
    ExecutiveApproval = "Executive Approval",
    TppBacklog = "TPP Backlog",
    Approved = "Approved",
    WorkCompleted = "Work Completed",
    NotApproved = "Not Approved",
    Withdrawn = "Withdrawn",
    InventoryOnly = "Inventory Only",
}