export enum PriorityCategory {
    Mandated = "Mandated",
    OrganizationImpacts = "Organization Impacts",
    Productivity = "Productivity",
    SafetyAccountability = "Safety Accountability"
}

export enum MandatedPriorityRefiner {
    ExecutivePriority = "Executive Priority",
    ExternalSystemOrDataDependencies = "External System or Data Dependencies",
    LegalOrLegislativeRequirements = "Legal or Legislative Requirements"
}

export enum OrganizationPriorityRefiner {
    CostAvoidance = "Cost Avoidance",
    ReducesProjectProgramRisk = "Reduces Project/Program Risk"
}

export enum ProductivityPriorityRefiner {
    CostSavings = "Cost Savings",
    TimeSavings = "Time Savings"
}

export enum SafetyPriorityRefiner {
    CyberSecurityThreatAvoidance = "Cyber Security Threat Avoidance",
    InjuryAndCasualtyReduction = "Injury and Casualty Reduction",
    WorkplaceIncidencePrevention = "Workplace Incidence Prevention"
}