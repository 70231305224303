import { observable, runInAction, action } from "mobx";
import * as configApi from './../apis/ConfigApi';
import { IAppConfig } from "../models/IAppConfig";

export class ConfigStore {
    @observable config: IAppConfig | null = null;

    @action
    public async fetchConfig(): Promise<void> {
        if (this.config === null) {
            const config = await configApi.getConfig();

            runInAction(() => {
                this.config = config;
            });
        }
    };
} 