import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUser, faRegistered, faPlus, faTrash, faEdit, faBan, faTrophy, faFolder, faStar, faSave,
    faBuilding, faTasks, faMinusCircle, faPlusCircle, faBars, faUndo, faEyeSlash, faEye, faExclamation,
    faCheck, faFileExcel, faExclamationTriangle, faSearch, faEraser, faArrowUp, faArrowDown, faDownload,
    faUpload, faShare, faSun, faMoon, faCalendarAlt, faCopy, faHandPaper, faRunning, faTimes, faThumbsUp,
    faThumbsDown, faSync, faPlay, faCalculator, faPercent, faCashRegister, faHandHoldingUsd, faChartLine,
    faHandPointLeft, faQuestion, faArrowLeft, faUserFriends, faCheckSquare, faBalanceScaleLeft, faStarHalfAlt,
} from '@fortawesome/free-solid-svg-icons';

import { faClock, faSquare } from '@fortawesome/free-regular-svg-icons';

export const initializeIcons = () => {
    library.add(faUser);
    library.add(faRegistered);
    library.add(faPlus);
    library.add(faEdit);
    library.add(faTrash);
    library.add(faBan);
    library.add(faTrophy);
    library.add(faFolder);
    library.add(faStar);
    library.add(faSave);
    library.add(faBuilding);
    library.add(faTasks);
    library.add(faMinusCircle);
    library.add(faPlusCircle);
    library.add(faBars);
    library.add(faUndo);
    library.add(faFileExcel);
    library.add(faEye);
    library.add(faEyeSlash);
    library.add(faExclamation);
    library.add(faCheck);
    library.add(faExclamationTriangle);
    library.add(faSearch);
    library.add(faEraser);
    library.add(faArrowUp);
    library.add(faArrowDown);
    library.add(faDownload);
    library.add(faUpload);
    library.add(faShare);
    library.add(faSun);
    library.add(faMoon);
    library.add(faCalendarAlt);
    library.add(faCopy);
    library.add(faHandPaper);
    library.add(faRunning);
    library.add(faTimes);
    library.add(faThumbsUp);
    library.add(faThumbsDown);
    library.add(faSync);
    library.add(faPlay);
    library.add(faCalculator);
    library.add(faClock);
    library.add(faPercent);
    library.add(faCashRegister);
    library.add(faHandHoldingUsd);
    library.add(faHandPointLeft);
    library.add(faChartLine);
    library.add(faQuestion);
    library.add(faArrowLeft);
    library.add(faUserFriends);
    library.add(faCheckSquare);
    library.add(faSquare);
    library.add(faBalanceScaleLeft);
    library.add(faStarHalfAlt);
}