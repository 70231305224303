export const formatPhoneNumber = (phoneNumber: string | undefined, extension: string | undefined): string => {
    if (phoneNumber === undefined) {
        return '';
    }
    if (phoneNumber.length === 10) {
        const formatted = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6)}`;
        return extension !== undefined && extension.length > 0
            ? `${formatted} (${extension})`
            : formatted;
    }
    else if (phoneNumber.length === 7) {
        const formatted = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 4)}`;
        return extension !== undefined && extension.length > 0
            ? `${formatted} (${extension})`
            : formatted;
    }
    else {
        console.log(`Implement formatPhoneNumber logic for: ${phoneNumber}`);
        return phoneNumber;
    }
}