import { observable, action, runInAction, IObservableArray } from 'mobx';
import * as enterpriseDataStewardApi from '../apis/EnterpriseDataStewardApi';
import { IEnterpriseDataSteward } from '../models/IEnterpriseDataSteward';

export class EnterpriseDataStewardStore {
    readonly enterpriseDataStewards: IObservableArray<IEnterpriseDataSteward> = observable.array([]);

    @action
    public async fetchAllItems(): Promise<void> {
        const enterpriseDataStewards = await enterpriseDataStewardApi.getAllEnterpriseDataStewards();
        runInAction(() => {
            this.enterpriseDataStewards.replace(enterpriseDataStewards);
        });
    };
}