import axios from 'axios';
import { IEnterpriseDataSteward } from '../models/IEnterpriseDataSteward';

const baseRestifyUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/EnterpriseDataSteward`;
const baseRouteUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/enterprise-data-steward`;

export const getAllEnterpriseDataStewards = async (): Promise<IEnterpriseDataSteward[]> => {
    const response = await axios.get(baseRestifyUrl);
    return response.data;
};

export const getEnterpriseDataStewardById = async (id: string): Promise<IEnterpriseDataSteward> => {
    const response = await axios.get(`${baseRestifyUrl}/${id}`);
    return response.data;
};

export const addEnterpriseDataSteward = async (iteration: IEnterpriseDataSteward): Promise<IEnterpriseDataSteward> => {
    const response = await axios.post(baseRouteUrl, iteration);
    return response.data;
};

export const updateEnterpriseDataSteward = async (iteration: IEnterpriseDataSteward): Promise<IEnterpriseDataSteward> => {
    const response = await axios.patch(`${baseRouteUrl}/${iteration._id}`, iteration);
    return response.data;
};

export const deleteEnterpriseDataSteward = async (id: string): Promise<IEnterpriseDataSteward> => {
    const response = await axios.delete(`${baseRouteUrl}/${id}`);
    return response.data;
};